import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/authContext';
import React, { useContext, useEffect } from 'react';
import Cookeis from 'js-cookie';

export const ProtectedRoute = (): any => {
  const { token, setToken, isExpired } = useContext(UserContext);
  const navigate = useNavigate();
  const localToken = Cookeis.get('token');
  useEffect(() => {
    if (localToken && !isExpired(localToken)) {
      setToken(localToken);
    }
  }, []);
  const location = useLocation();
  useEffect(() => {
    if (!token && !localToken) {
      //user is not authenticated
      return navigate('/login', {
        state: { pathname: location.pathname, search: location.search }
      });
    }
  }, []);
  return (
    <>
      <Outlet />
    </>
  );
};
