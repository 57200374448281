import {
  kbSentenceItem,
  NetNewSentenceItem,
  sentenceItem
} from '../models/interfaces';
import { filterEnum } from '../models/interfaces';

export const getRowColor = (sentence: sentenceItem) => {
  let color = 'black';

  switch (true) {
    case sentence.type === 'Expectation':
      color = '#FFBF00';
      break;
    case sentence.type === 'Obligation':
      color = '#FF0000';
      break;
    default:
      color = '#000000';
      break;
  }
  return color;
};

const noHighlight = {
  fill: 'white',
  fillOpacity: '0.1',
  strokeWidth: '0',
  stroke: 'none'
};

const greenHighLight = {
  fill: '#19FC05',
  fillOpacity: '0.35',
  strokeWidth: '0.1',
  stroke: 'black'
};

const includesFilterValue = (
  sentence: sentenceItem,
  burdenAttFilter: string[]
) => {
  if (sentence.burden_attribution.length === 0) {
    return false;
  }
  for (const value of sentence.burden_attribution[0]) {
    if (burdenAttFilter.includes(value)) {
      return true;
    }
  }
  return false;
};

export const rectangleStyle = (
  sentence: sentenceItem,
  selectedItems: sentenceItem[],
  filterValue: filterEnum | null,
  controlFilter: string,
  burdenAttFilter: string[]
) => {
  let style: {
    fill?: string;
    strokeWidth?: string;
    stroke?: string;
    fillOpacity?: string;
    strokeOpacity?: string;
    textDecoration?: string;
  } = {};
  const isFiltering = filterValue ? true : false;
  let isFilterItem = isCurrentFiltered(
    sentence,
    filterValue,
    burdenAttFilter,
    controlFilter
  );
  // only show the filterd items highlitghted
  if (isFiltering) {
    if (isFilterItem) {
      style = { ...greenHighLight };
    } else {
      style = { ...noHighlight };
    }
  }
  if (!isFiltering) {
    style.fill = 'white';
    style.fillOpacity = '0.2';
    style.stroke = 'none';
  }

  return style;
};

const isFinancialDueDate = (sentence: sentenceItem) => {
  if (
    sentence.burden &&
    sentence.people_org &&
    sentence.people_orgs.length > 0 &&
    sentence.date_arguments.length > 0 &&
    sentence.burden_attribution.length > 0 &&
    sentence.Time_Information &&
    sentence.Time_Entities.length > 0
  ) {
    return true;
  } else {
    return false;
  }
};
const isObligationDueDate = (sentence: sentenceItem) => {
  if (
    sentence.burden &&
    sentence.Time_Information &&
    sentence.date_arguments.length > 0 &&
    sentence.Time_Entities.length > 0
  ) {
    return true;
  } else {
    return false;
  }
};

export const isCurrentFiltered = (
  sentence: sentenceItem | NetNewSentenceItem,
  filterValue: filterEnum | null,
  burdenAttFilter: string[],
  controlFilter: string
) => {
  let isFilterItem = false;
  switch (true) {
    case filterValue === filterEnum.due_dates &&
      (isFinancialDueDate(sentence) || isObligationDueDate(sentence)):
      isFilterItem = true;
      break;
    // updated
    case filterValue === filterEnum.Financial_Information &&
      (sentence.Financial_Information ||
        sentence.predicted_label_control === 'Financial Details'):
      isFilterItem = true;
      break;
    // updated
    case filterValue === filterEnum.service &&
      sentence.Percent_Information &&
      sentence.Time_Information &&
      sentence.Time_Entities.length > 0:
      isFilterItem = true;
      break;
    case filterValue === filterEnum.burden_attribution &&
      sentence.burden &&
      sentence.people_org &&
      includesFilterValue(sentence, burdenAttFilter):
      isFilterItem = true;
      break;
    // updated
    case filterValue === filterEnum.location &&
      sentence.location &&
      sentence.locations.length > 0:
      isFilterItem = true;
      break;
    // updated

    // todo remove
    case filterValue === filterEnum.terminiation &&
      (sentence.termination ||
        sentence.penalties ||
        sentence.predicted_label_control === 'Termination'):
      isFilterItem = true;
      break;
    // updated
    case filterValue === filterEnum.control &&
      sentence.predicted_label_control === controlFilter:
      isFilterItem = true;
      break;
    case filterValue === filterEnum.starred && sentence.starred:
      isFilterItem = true;
      break;
    case filterValue === filterEnum.unclassified_burden &&
      sentence.burden &&
      sentence.predicted_label_control === 'General':
      isFilterItem = true;
      break;
    case filterValue === filterEnum.project_timelines &&
      (sentence.predicted_label_control === 'Project Timeline' ||
        sentence.date_arguments.length > 0):
      isFilterItem = true;
      break;
    case filterValue === filterEnum.netNew &&
      sentence.netNew &&
      sentence.netNew.length > 0:
      isFilterItem = true;
      break;
  }
  return isFilterItem;
};
