import * as xlsx from 'xlsx';
import {
  IControl,
  IDomain,
  documentTableItem,
  sentenceItem
} from '../models/interfaces';

export const createData = (
  sentences: sentenceItem[],
  domains: IDomain[],
  controls: IControl[],
  currentDoc: documentTableItem
) => {
  const dataToExport: any[] = [];
  for (const sentence of sentences) {
    const [domainToUse] = domains.filter(
      (ele) => ele.domainName.trim() === sentence.predicted_label_domain.trim()
    );

    const [controlToUse] = controls.filter(
      (ele) =>
        ele.controlName.trim() === sentence.predicted_label_control.trim()
    );
    const tempObject = { ...sentence, ...domainToUse, ...controlToUse };
    const { _id, documentId, ...propertiesToInclude } = tempObject;
    dataToExport.push({
      ...propertiesToInclude,
      documentName: currentDoc.documentFileName
    });
  }
  let workbook = xlsx.utils.book_new();
  let worksheet = xlsx.utils.json_to_sheet(dataToExport!);
  xlsx.utils.book_append_sheet(workbook, worksheet, 'sheet one');
  const blob = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
  const url = window.URL.createObjectURL(
    new Blob([blob], { type: 'application/octet-stream' })
  );
  const a = document.createElement('a');
  a.href = url;
  a.download = `${currentDoc.documentFileName}.xlsx`;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const QandA_CSV = (
  qaSentences: sentenceItem[],
  currentDoc: documentTableItem
) => {
  const dataToExport: any[] = [];
  for (const sentence of qaSentences) {
    const { text, answer } = sentence;
    dataToExport.push({ text, answer });
  }
  let workbook = xlsx.utils.book_new();
  let worksheet = xlsx.utils.json_to_sheet(dataToExport);
  worksheet['!cols'] = [{ wch: 40 }, { wch: 40 }];
  xlsx.utils.book_append_sheet(workbook, worksheet, 'sheet one');
  const blob = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
  const url = window.URL.createObjectURL(
    new Blob([blob], { type: 'application/octet-stream' })
  );
  const a = document.createElement('a');
  a.href = url;
  a.download = `${currentDoc.documentFileName}.xlsx`;
  a.click();
  window.URL.revokeObjectURL(url);
};
