// const winkNLP = require( 'wink-nlp' );
import winkNLP  from 'wink-nlp';
import model from 'wink-eng-lite-web-model'
const nlp = winkNLP( model )

export default function sentencesNLP(data: { toString: () => any; }) {
  let returnArray = [];
  let sentenceData = data.toString();
  const doc = nlp.readDoc( sentenceData );
  let newArray = doc.sentences().out() ;
  for(const ele of newArray) {
    let newele = ele.replace(/(\r\n|\n|\r)/gm, " ");
    newele = newele.trim().replace(/\s+/g, " ");
    returnArray.push(newele);
  }
  return returnArray;

}