import {
  Button,
  FormField,
  Grid,
  Icon,
  Input,
  Textarea
} from '@cloudscape-design/components';
import { IDomain } from '../../models/interfaces';
import { useContext, useState } from 'react';
import { UserContext } from '../../context/authContext';
import axios from 'axios';
import { DocumentContext } from '../../context/documentContext';

export const DomainLayout = (props: { domain: IDomain }) => {
  const { domain } = props;
  const { auth } = useContext(UserContext);
  const { getDomains } = useContext(DocumentContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentName, setCurrentName] = useState<string>(domain.domainName);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentCode, setCurrentCode] = useState<string>(domain.domainCode);
  const [currentDescription, setCurrentDescrtiption] = useState<string>(
    domain.domainDescription
  );
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const ShowSave = () => {
    if (
      currentName !== domain.domainName ||
      currentCode !== domain.domainCode ||
      currentDescription !== domain.domainDescription
    ) {
      return true;
    } else {
      return false;
    }
  };

  const updateDomain = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API}/updateDomain`,
        { _id: domain._id, domainDescription: currentDescription },
        auth
      );
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 2000);
      getDomains();
    } catch (e) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 2000);
    }
  };

  return (
    <Grid
      gridDefinition={[
        { colspan: { default: 1, m: 1 } },
        { colspan: { default: 3, m: 3 } },
        { colspan: { default: 7, m: 7 } },
        { colspan: { default: 1, m: 1 } }
      ]}
    >
      <FormField stretch>
        <Input
          value={currentCode}
          disabled
          // onChange={(event) => setCurrentCode(event.detail.value)}
        />
      </FormField>
      <FormField stretch>
        <Input
          value={currentName}
          disabled
          // onChange={(event) => setCurrentName(event.detail.value)}
        />
      </FormField>
      <FormField stretch>
        <Textarea
          value={currentDescription}
          onChange={(event) => setCurrentDescrtiption(event.detail.value)}
        />
      </FormField>
      {ShowSave() ? (
        <Button iconName="upload" onClick={() => updateDomain()}></Button>
      ) : null}
      {showSuccess ? <Icon name="status-positive" /> : null}
      {showError ? <Icon name="status-negative" /> : null}
    </Grid>
  );
};
