import {
  Table,
  Container,
  Button,
  SpaceBetween,
  Header,
  Box,
  ContentLayout,
  ExpandableSection,
  Select,
  TableProps,
  Textarea,
  ColumnLayout,
  Link
} from '@cloudscape-design/components';

// context
// const evidences = [
//   { name: 'evidence 1' },
//   { name: 'evidence 2' },
//   { name: 'evidence 3' }
// ];
export const DocListHelpPanel = () => {

  return (
    <Container header={<Header variant="h2">{`List Documents`}</Header>}>
      <p>
        This page displays all your uploaded documents and provides tools to
        manage, analyze, and integrate them into your Knowledge Base.
      </p>
      <>
        <h4>Available Options</h4>
        <ul>
          <li>
            <b>Create Reports:</b> Generate detailed reports for selected
            documents
          </li>
          <li>
            <b>Delete Selected:</b> Remove chosen documents from your list
          </li>
          <li>
            <b>View Document:</b> Access the full obligation analysis of a
            selected document
          </li>
          <li>
            <b>Add to KB:</b> Integrate a document into your Knowledge Base for
            enhanced searchability and cross-referencing
          </li>
        </ul>

        <h4>Option Details</h4>
        <h5>Create Reports</h5>
        <ul>
          <li>Access comprehensive document summaries and insights</li>
          <li>Choose from various report types tailored to your needs</li>
          <li>Export reports for sharing or further analysis</li>
        </ul>

        <h5>View Document</h5>
        <ul>
          <li>Explore AI-powered obligation analysis results</li>
          <li>
            Navigate through categorized information and highlighted key phrases
          </li>
          <li>Review identified obligations, deadlines, and critical terms</li>
          <li>Add notes, redline text, and collaborate with your team</li>
        </ul>

        <h5>Adding Documents to Knowledge Base</h5>
        <ul>
          <li>Enhance document searchability and traceability</li>
          <li>Categorize documents for efficient organization</li>
          <li>Enable powerful cross-document referencing</li>
        </ul>

        <h4>Knowledge Base</h4>
        <p>
          Adding documents to your Knowledge Base unlocks powerful features:
        </p>
        <ul>
          <li>
            Advanced Searchability: Quickly find relevant information across all
            your documents
          </li>
          <li>
            Cross-Document Analysis: Identify connections and similarities
            between different documents
          </li>
          <li>
            Improved Compliance: Ensure all relevant documents are considered in
            decision-making processes
          </li>
          <li>
            Enhanced Collaboration: Share organized, searchable information with
            your team
          </li>
        </ul>

        <h5>Benefits of using the Knowledge Base</h5>
        <p>
          The Knowledge Base feature offers significant advantages in tracing
          obligations across various document types. Here are some practical
          examples:
        </p>

        <ul>
          <li>
            <b>Contract to Regulation Traceability:</b> Link contract clauses to
            relevant building regulations
          </li>
          <li>
            <b>Contract and Addendum Mapping:</b> Map changes to obligations
            between documents to track variations in requirements and discover
            any additional compliance requirements
          </li>
          <li>
            <b>Technical Manual Integration:</b> Quickly cross-reference
            requirements to relevant specifications in technical manuals.
          </li>
          <li>
            <b>Cross-Project Obligation Tracking:</b>Manage multiple projects
            with similar obligations across different contracts and optimize
            allocation of work for overlapping resources
          </li>
          <li>
            <b>Regulatory Compliance Verification:</b> Identify applicable codes
            and rules not included in original plans
          </li>
          <li>
            <b>Historical Reference and Consistency:</b>Reference obligations
            and information in existing documents and their outcomes to refine
            the language and avoid potential compliance risk.
          </li>
        </ul>

        <h5>How to Add a Document to the Knowledge Base</h5>
        <ol>
          <li>Select a document from the list</li>
          <li>Click the "Add to KB" button</li>
          <li>
            In the "Enhance Document Searchability" modal:
            <ul>
              <li>
                Choose the appropriate Knowledge Base library:
                <ul>
                  <li>Rules & Regulations</li>
                  <li>Governance Documents</li>
                  <li>Executed Contracts</li>
                  <li>Memorandums</li>
                  <li>Reference</li>
                  <li>Other</li>
                </ul>
              </li>
              <li>Click "Add Document" to confirm</li>
            </ul>
          </li>
          <li>
            The document will be processed and made available for advanced
            searching and mapping
          </li>
        </ol>

        <h4>Tips</h4>
        <ul>
          <li>
            Regularly review and organize your document list for efficient
            management
          </li>
          <li>
            Utilize the Knowledge Base feature to maximize the value of your
            document analysis
          </li>
          <li>
            Combine "View Document" and "Create Reports" for comprehensive
            document understanding
          </li>
        </ul>
      </>
    </Container>
  );
};
