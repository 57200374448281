import { SpaceBetween } from '@cloudscape-design/components';
import { DocumentTableView } from './DocumentTableView';
import { FolderTableView } from './FolderTableView';

export const DocumentViewerContainer = () => {
  return (
    <div
      style={{
        overflowY: 'scroll',
        scrollbarWidth: 'none'
      }}
    >
      <SpaceBetween size={'xs'} direction="vertical">
        {/* {selectedProjects.length > 0 && ( */}
        <SpaceBetween size={'xs'} direction="vertical">
          <FolderTableView />
          <DocumentTableView />
        </SpaceBetween>
        {/* )} */}
      </SpaceBetween>
    </div>
  );
};
