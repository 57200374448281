import axios from 'axios';

export const getKBDocLinkAndOpen = async (
  documentId: string,
  pageNum: number,
  auth: any
) => {
  try {
    // setPreLoading(true);
    const presignedUrl = await axios.post(
      `${process.env.REACT_APP_KORU_API}/get-presigned-url?type=getDocument`,
      {
        documentId: documentId
      },
      auth
    );
    const urlWithPageNum = presignedUrl.data.url + `#page=${pageNum}`;
    window.open(urlWithPageNum, '_blank');
    // setPreLoading(false);
  } catch (e) {
    const error = e as Error;
    console.log('error getting doc', error.message);
  }
};
