import { sentenceItem } from "../../../models/interfaces";

export const ShowSvgArrow = (ele: sentenceItem) => {

  const upArrow =
    ele.mappedTargetSentences && ele.mappedTargetSentences.length > 0;
  const downArrow =
    ele.mappedParentSentences && ele.mappedParentSentences.length > 0;
  const both = upArrow && downArrow;
  if (both) {
    return (
      <g transform={`rotate( 180 10,7.5)`}>
        <line
          stroke="black"
          strokeWidth={2}
          x1="8"
          y1="0"
          x2="8"
          strokeLinecap="round"
          y2="15"
        ></line>
        <line
          stroke="black"
          strokeWidth={2}
          x1="12"
          y1="0"
          x2="12"
          strokeLinecap="round"
          y2="15"
        ></line>
        <polyline
          stroke="black"
          strokeLinecap="round"
          strokeWidth={2}
          fill="none"
          points="4,5 8,0"
        ></polyline>
        <polyline
          stroke="black"
          strokeLinecap="round"
          strokeWidth={2}
          fill="none"
          points="12,15 16,10"
        ></polyline>
      </g>
    );
  }
  if (upArrow) {
    return (
      <g>
        <line
          stroke="black"
          strokeWidth={2}
          strokeLinecap="round"
          x1="10"
          y1="0"
          x2="10"
          y2="15"
        ></line>
        <polyline
          stroke="black"
          strokeWidth={2}
          strokeLinecap="round"
          fill="none"
          points="5,7.5 10,0 15,7.5"
        ></polyline>
      </g>
    );
  }
  if (downArrow) {
    return (
      <g transform={`rotate( 180 10,7.5)`}>
        <line
          stroke="black"
          strokeWidth={2}
          strokeLinecap="round"
          x1="10"
          y1="0"
          x2="10"
          y2="15"
        ></line>
        <polyline
          stroke="black"
          strokeWidth={2}
          strokeLinecap="round"
          fill="none"
          points="5,7.5 10,0 15,7.5"
        ></polyline>
      </g>
    );
  }
  return null;
};
