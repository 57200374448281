import React, { useContext, useEffect, useState } from 'react';
import { DocumentContext } from '../../context/documentContext';
import {
  Button,
  Checkbox,
  Container,
  FormField,
  Header,
  Input,
  Multiselect,
  ProgressBar,
  SpaceBetween,
  Spinner,
  TextContent
} from '@cloudscape-design/components';
import {
  QandASentence,
  kbSentenceItem,
  KBOptions
} from '../../models/interfaces';
import { FreeformKnowledgebase } from './freefromKBResults';
import { BaseKeyDetail } from '@cloudscape-design/components/internal/events';
// import { KBItemTypes } from '../../models/documentTableModel';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { CircleSpinner } from './testSpinner';
import axios from 'axios';
import { UserContext } from '../../context/authContext';
import PopoverWrapper from './popoverWrapper';
export const FreeformAnalysis = () => {
  const {
    freeHandleAnalyze,
    setFreeformeSelectedItemsKB,
    qandaPassthroughAnalysis
  } = useContext(DocumentContext);
  const { auth } = useContext(UserContext);
  const [text, setText] = useState<string>('');
  const [kbresults, setKBresults] = useState<kbSentenceItem[]>([]);
  const [qaResults, setQAResults] = useState<QandASentence[]>([]);
  const [kbSearching, setKBSearching] = useState<boolean>(false);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [searchComplete, setSearchComplete] = useState<boolean>(false);
  const [QAChecked, setQAChecked] = useState<boolean>(false);
  const [KBChecked, setKBChecked] = useState<boolean>(false);
  const [kbFilters, setKBFilters] = useState<OptionDefinition[]>([]);

  const clearAll = () => {
    setKBresults([]);
    setFreeformeSelectedItemsKB([]);
    setText('');
  };

  // useEffect(() => {
  //   setProgressValue(100);
  // setKBSearching(false);
  // }, [kbresults]);

  const handleSearch = async () => {
    setKBresults([]);
    setQAResults([]);
    setKBSearching(true);
    setSearchComplete(false);
    if (QAChecked) {
      try {
        const qaResult = await qandaPassthroughAnalysis(text);
        console.log('qa search resault', qaResult);
        setQAResults(qaResult.results);
      } catch (e) {
        setQAResults([]);
        console.log('Error fetching', e);
      }
    }
    if (KBChecked) {
      setKBresults([]);
      setProgressValue(0);
      try {
        const result = await freeHandleAnalyze(text, kbFilters);
        setKBresults(result);
        // setProgressValue(100);
        // setTimeout(() => {
        //   setKBresults(result);
        //   setKBSearching(false);
        // }, 1000);
      } catch (e) {
        setKBresults([]);
      }
    }
    setKBSearching(false);
    setSearchComplete(true);
  };

  function handleKeyDown(event: CustomEvent<BaseKeyDetail>): void {
    console.log('event from input', event);
    if (event.detail.key === 'Enter') {
      handleSearch();
    }
  }

  // const warmUP = async () => {
  //   try {
  //     const embeddingBody = [{ text: 'some text', _id: 'testid' }];
  //     const embeddingResult = await axios.post(
  //       `${process.env.REACT_APP_KORU_API}/embedding`,
  //       embeddingBody,
  //       auth
  //     );
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // useEffect(() => {
  //   warmUP();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Container
      fitHeight
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="s">
              <Button onClick={() => clearAll()}>Reset</Button>
              <PopoverWrapper
                popoverContent={'Search with selected criteria'}
                sideToShow="LEFT"
                aboveBelow="BOTTOM"
              >
                <Button onClick={() => handleSearch()}>Search</Button>
              </PopoverWrapper>
            </SpaceBetween>
          }
        >
          <SpaceBetween size={'s'} direction="horizontal">
            <TextContent>
              <h4>Search</h4>
            </TextContent>
            <Checkbox
              checked={QAChecked}
              onChange={({ detail }) => setQAChecked(detail.checked)}
            >
              Q&A Map
            </Checkbox>
            <Checkbox
              checked={KBChecked}
              onChange={({ detail }) => setKBChecked(detail.checked)}
            >
              Knowledge Base
            </Checkbox>
            {KBChecked && (
              <PopoverWrapper
                popoverContent={
                  <p style={{ fontSize: '0.8em', padding: '1% 1%' }}>
                    Select specific types or leave blank for all types
                  </p>
                }
                sideToShow="RIGHT"
                aboveBelow="BOTTOM"
              >
                <Multiselect
                  onChange={({ detail }) =>
                    setKBFilters([...detail.selectedOptions])
                  }
                  // hideTokens
                  selectedOptions={kbFilters}
                  options={KBOptions}
                  placeholder={
                    kbFilters.length > 0
                      ? 'Use Selected Filters'
                      : 'Select Filters'
                  }
                ></Multiselect>
              </PopoverWrapper>
            )}
          </SpaceBetween>
        </Header>
      }
    >
      <SpaceBetween size={'s'}>
        <FormField stretch description="Enter text" label="">
          <Input
            value={text}
            type="text"
            onChange={(event) => setText(event.detail.value)}
            // onKeyDown={(event) => handleKeyDown(event)}
          />
        </FormField>
      </SpaceBetween>
      <div
        style={{
          height: '75vh',
          overflowY: 'scroll',
          width: '100%',
          scrollbarWidth: 'none'
        }}
      >
        {kbSearching ? (
          <div
            style={{
              height: '100%',
              width: '100%',
              outline: '1px solid green',
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircleSpinner />
          </div>
        ) : null}

        {searchComplete ? (
          <FreeformKnowledgebase
            kbresults={kbresults}
            qaResults={qaResults}
            QAChecked={QAChecked}
            KBChecked={KBChecked}
            searchComplete={searchComplete}
          />
        ) : null}
      </div>
    </Container>
  );
};
