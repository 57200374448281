import { useNavigate, useSearchParams } from 'react-router-dom';
import { IVideos, videosObject, videoItems } from '../landingPage/videos';
import { useEffect, useState } from 'react';
import './videos.css';
export const Videos = () => {
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();
  const [links, setLinks] = useState<videoItems[]>([]);
  const [pageHeading, setPageHeading] = useState<string>('');
  useEffect(() => {
    const cardValue = queryParameters.get('card');
    if (!cardValue) {
      navigate('/app/landing');
    } else {
      setLinks(videosObject[cardValue].videos);
      setPageHeading(videosObject[cardValue].pageHeading);
    }
  }, []);

  return (
    <>
      <h2>{pageHeading}</h2>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap'
        }}
      >
        {links.map((ele, index) => {
          return (
            <div className="card">
              {/* <div className="expand-icon">&#x26F6;</div> */}
              <div className="card-number">{index + 1}</div>
              <div className="card-heading">{ele.heading}</div>
              {/* <iframe
                className="card-iframe"
                src={ele.link}
                title=" "
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-pictur; full-screen"
                allowFullScreen
                // title="YouTube video"
              ></iframe> */}
              <video width="600" controls>
                <source src={ele.link} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <ul className="card-list">
                {ele.items.map((items) => {
                  return <li>{items}</li>;
                })}
                {/* <li>Second bullet point</li>
              <li>Third bullet point</li> */}
              </ul>
            </div>
          );
        })}
      </div>
    </>
  );
};

// <>
//   <h2>{pageHeading}</h2>
//   <div
//     style={{
//       width: '70vw',
//       display: 'flex',
//       flexDirection: 'row',
//       flexWrap: 'wrap'
//     }}
//   >
//     {links.map((ele) => {
//       return (
//         <div
//           style={{
//             width: '20vw',
//             height: '40vh',
//             margin: '1% 1%',
//             display: 'flex',
//             flexDirection: 'column',
//             alignContent: 'center',
//             justifyContent: 'center',
//             border: '1px solid black',
//             borderRadius: '10px',
//             padding: '1% 1%'
//           }}
//         >
//           <iframe
//             style={{
//               width: '85%',
//               height: '85%',
//               margin: '0 auto'
//             }}
//             src={ele.link}
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//             allowFullScreen
//             title="YouTube video"
//           />
//           <p>{ele.heading}</p>
//           <ul style={{ margin: '0 0' }}>
//             {ele.items.map((item) => (
//               <li>{item}</li>
//             ))}
//           </ul>
//         </div>
//       );
//     })}
//   </div>
// </>
