import { sentenceItem } from '../models/interfaces';

export const getDomainObligations = (sentences: sentenceItem[]) => {
  const obligations = sentences.reduce((acc: string[], curr: sentenceItem) => {
    if (
      !acc.includes(curr.predicted_label_domain) &&
      curr.predicted_label_language === 'ComplianceLanguage' &&
      curr.burden
    ) {
      acc.push(curr.predicted_label_domain);
    }
    return acc;
  }, [] as string[]);
  return obligations;
};
export const getDomainExpectations = (sentences: sentenceItem[]) => {
  const expectations = sentences.reduce((acc: string[], curr: sentenceItem) => {
    if (
      !acc.includes(curr.predicted_label_domain) &&
      curr.predicted_label_language === 'ComplianceLanguage' &&
      !curr.burden &&
      curr.confidence_score_language > 0.8
    ) {
      acc.push(curr.predicted_label_domain);
    }
    return acc;
  }, [] as string[]);
  return expectations;
};

export const getAllConstructionSentences = (sentences: sentenceItem[]) => {
  const allConstruction = sentences.reduce((acc, curr) => {
    if (
      (curr.predicted_label_language === 'ComplianceLanguage' && curr.burden) ||
      (curr.predicted_label_language === 'ComplianceLanguage' &&
        !curr.burden &&
        curr.confidence_score_language > 0.8)
    ) {
      acc.push(curr);
    }
    return acc;
  }, [] as sentenceItem[]);
  return allConstruction;
};
