import {
  Button,
  FormField,
  Grid,
  Icon,
  Input,
  Select,
  Textarea
} from '@cloudscape-design/components';
import { IControl } from '../../models/interfaces';
import { useContext, useState } from 'react';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import axios from 'axios';
import { UserContext } from '../../context/authContext';
import { DocumentContext } from '../../context/documentContext';

export const ControlLayout = (props: { control: IControl }) => {
  const { control } = props;
  const { auth } = useContext(UserContext);
  const { getControls } = useContext(DocumentContext)
  const [currentName, setCurrentName] = useState<string>(control.controlName);
  const [currentStatus, setCurrentStatus] = useState<OptionDefinition>({
    label: `${control.controlStatus}`,
    value: `${control.controlStatus}`
  });
  const [currentDescription, setCurrentDescrtiption] = useState<string>(
    control.controlDescription
  );
  const [currentOwners, setCurrentOwners] = useState<string>(control.controlOwners);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentDepartment, setCurrentDepartment] = useState<string>(control.controlDepartment)
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const ShowSave = () => {
    if (
      currentName !== control.controlName ||
      currentStatus.label !== control.controlStatus ||
      currentDescription !== control.controlDescription || currentOwners !== control.controlOwners    ) {
      return true;
    } else {
      return false;
    }
  };

  const updateControl = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API}/updateControl`,
        {
          _id: control._id,
          controlDescription: currentDescription,
          controlDepartment: currentDepartment,
          controlOwners: currentOwners,
          controlStatus: currentStatus.value,
        },
        auth
      );
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 2000);
      getControls()
    } catch (e) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 2000);
    }
  };

  return (
    <Grid
      gridDefinition={[
        { colspan: { default: 2, m: 2 } },
        { colspan: { default: 2, m: 2 } },
        { colspan: { default: 1, m: 1 } },
        { colspan: { default: 6, m: 6 } },
        { colspan: { default: 1, m: 1 } }
      ]}
    >
      <FormField stretch>
        <Input
          value={currentName}
          disabled
          onChange={(event) => setCurrentName(event.detail.value)}
        />
      </FormField>
      <FormField stretch>
        <Input
          value={currentOwners}
          // disabled
          onChange={(event) => setCurrentOwners(event.detail.value)}
        />
      </FormField>
      <FormField stretch>
        <Select
          selectedOption={currentStatus}
          onChange={({ detail }) => setCurrentStatus(detail.selectedOption)}
          options={[
            { label: 'Implemented', value: 'Implemented' },
            { label: 'Remediating', value: 'Remediating' },
            { label: 'Planned', value: 'Planned' }
          ]}
        />
      </FormField>
      <FormField stretch>
        <Textarea
          value={currentDescription}
          onChange={(event) => setCurrentDescrtiption(event.detail.value)}
        />
      </FormField>
      {ShowSave() ? (
        <Button iconName="upload" onClick={() => updateControl()}></Button>
      ) : null}
      {showSuccess ? <Icon name="status-positive" /> : null}
      {showError ? <Icon name="status-negative" /> : null}
    </Grid>
  );
};
