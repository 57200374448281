import {
  Box,
  Button,
  Container,
  Grid,
  Header,
  SpaceBetween,
  Spinner
} from '@cloudscape-design/components';
import { useContext, useEffect, useState } from 'react';
import { filteredViewSentences } from '../../helpers/filteredView';
import { DocumentContext } from '../../context/documentContext';
import { UserContext } from '../../context/authContext';
import { createFile } from '../../helpers/createDocx';
import { getOwnership } from '../../helpers/getOwnership';
import { getReportTitle } from '../../helpers/getReportType';
import { config, report } from 'process';
import { documentTableItem, sentenceItem } from '../../models/interfaces';
import {
  getAllConstructionSentences,
  getDomainExpectations,
  getDomainObligations
} from '../../helpers/getOverViews';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { CircleSpinner } from './testSpinner';

export const ReportsView = () => {
  const { sentences, setSentences, currentDoc, setCurrentDoc, setMainAlert } =
    useContext(DocumentContext);
  const { auth } = useContext(UserContext);
  const [reportObject, setReportObject] = useState<{
    [key: string]: sentenceItem[];
  }>({});
  const [componentReady, setComponentReady] = useState<boolean>(false);
  const [queryParameters] = useSearchParams();

  const createSpecificReport = (reportType: string) => {
    let reportTypeToUse = reportType;
    console.log('creating report', reportType);
    if (reportType === 'all') {
      createFile(currentDoc.documentFileName, sentences, 'Redline Report');
      return;
    }
    if (reportType === 'ownership') {
      const ownerList = getOwnership(sentences);
      reportTypeToUse = `ownership@${ownerList.join(':::')}`;
    }
    if (reportType !== 'construction') {
      const filteredSentences = filteredViewSentences(
        reportTypeToUse,
        sentences
      );
      const reportTitle = getReportTitle(reportTypeToUse);
      if (filteredSentences.sentences.length > 0) {
        createFile(
          currentDoc.documentFileName,
          filteredSentences.sentences,
          reportTitle
        );
      }
    }
    if (reportType === 'construction') {
      const oblicgtionsAndEcpectations = getAllConstructionSentences(sentences);
      if (oblicgtionsAndEcpectations.length > 0) {
        createFile(
          currentDoc.documentFileName,
          oblicgtionsAndEcpectations,
          'Obligations and Expectations'
        );
      }
    }
  };

  const createReportMap = () => {
    const types = [
      'general-obligation',
      'service',
      'dates',
      'financial',
      'places',
      'people'
    ];
    const reportMap: { [key: string]: sentenceItem[] } = {};
    for (const type of types) {
      const result = filteredViewSentences(type, sentences);
      if (result.sentences.length > 0) {
        reportMap[type] = result.sentences;
      }
    }
    console.log('report obj', reportMap);
    const getOwners = getOwnership(sentences);
    if (getOwners.length > 0) {
      reportMap['ownership'] = [];
    }
    const allConstrunction = getAllConstructionSentences(sentences);
    if (allConstrunction.length > 0) {
      reportMap['construction'] = allConstrunction;
    }
    setReportObject(reportMap);
    setComponentReady(true);
  };

  const getAllSentencesForAnalysysDoc = async () => {
    try {
      // get and set the first chuck of sentences, then get the balance and addd them to the state.
      const queryValue = queryParameters.get('documentId');
      if (!queryValue) {
        setMainAlert({
          showAlert: true,
          message: 'Url is missing a reference no',
          alertType: 'error'
        });
      } // TODO add error warning.
      const thisDocumentCall = await axios.post(
        `${process.env.REACT_APP_KORU_API}/document?type=documentById`,
        { documentId: queryValue },
        auth
      );
      const thisDocument: documentTableItem = thisDocumentCall.data.result[0];
      setCurrentDoc(thisDocument);
      const localSentences = [];
      if (thisDocument.chunks.length > 0) {
        for (let i = 0; i < thisDocument.chunks.length; i++) {
          console.log('getting next chunk', i);
          const tempResult = await axios.post(
            `${process.env.REACT_APP_KORU_API}/sentence?type=all`,
            { documentId: queryValue, chunkNo: i },
            auth
          );
          const newSentences = tempResult.data
            .result as unknown as sentenceItem[];
          localSentences.push(...newSentences);
        }
        setSentences(localSentences);
      } else {
        setMainAlert({
          showAlert: true,
          message:
            'There was an error loading this document. Please refresh your browser. If the problem persists please contact support.',
          alertType: 'error'
        });
      }
    } catch (e) {
      console.log('error from get sentences local', e);
      const error = e as Error;
      setMainAlert({
        showAlert: true,
        message: error.message,
        alertType: 'error'
      });
    }
  };

  useEffect(() => {
    setComponentReady(false);
    createReportMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentences]);

  useEffect(() => {
    setComponentReady(false);
    getAllSentencesForAnalysysDoc();
  }, []);
  return (
    <Container
      header={
        <Header>
          <SpaceBetween direction="vertical" size="xxs">
            <h1>Reports</h1>
            <h6 style={{ margin: '0 0' }}>Print reports.</h6>
          </SpaceBetween>
        </Header>
      }
    >
      {componentReady ? (
        <SpaceBetween direction="vertical" size="s">
          <Box textAlign="center">
            <Button onClick={() => createSpecificReport('all')}>
              Full Report
            </Button>
          </Box>
          <Grid
            gridDefinition={[
              { colspan: { default: 4, m: 4 } },
              { colspan: { default: 4, m: 4 } },
              { colspan: { default: 4, m: 4 } }
            ]}
          >
            <Container
              fitHeight
              header={<Header>Obligations & Expectations</Header>}
            >
              <SpaceBetween size={'xl'} alignItems="center">
                <Button
                  disabled={!reportObject['construction']}
                  onClick={() => createSpecificReport('construction')}
                >
                  Construction Obligations
                </Button>
              </SpaceBetween>
            </Container>
            <Container fitHeight header={<Header>Negotiation Areas</Header>}>
              <SpaceBetween size={'xl'} alignItems="center">
                <Button
                  disabled={!reportObject['service']}
                  onClick={() => createSpecificReport('service')}
                >
                  Service level requirements
                </Button>
                <Button
                  disabled={!reportObject['dates']}
                  onClick={() => createSpecificReport('dates')}
                >
                  Obligations due dates
                </Button>
                <Button
                  disabled={!reportObject['ownership']}
                  onClick={() => {
                    createSpecificReport('ownership');
                  }}
                >
                  Burden Assignment
                </Button>
              </SpaceBetween>
            </Container>
            <Container
              fitHeight
              header={<Header>Important Information</Header>}
            >
              <SpaceBetween size={'xl'} alignItems="center">
                <Button
                  disabled={!reportObject['financial']}
                  onClick={() => createSpecificReport('financial')}
                >
                  Financial Information
                </Button>
                <Button
                  disabled={!reportObject['people']}
                  onClick={() => createSpecificReport('people')}
                >
                  People, Roles & Responsibilities
                </Button>
                <Button
                  disabled={!reportObject['places']}
                  onClick={() => createSpecificReport('places')}
                >
                  Places and Locations
                </Button>
              </SpaceBetween>
            </Container>
          </Grid>
        </SpaceBetween>
      ) : (
        <SpaceBetween direction="vertical" alignItems="center" size={'xl'}>
          {/* <Spinner size="large"></Spinner> */}
          <CircleSpinner />
        </SpaceBetween>
      )}
    </Container>
  );
};
