import {
  Alert,
  AppLayout,
  Box,
  Button,
  Checkbox,
  Modal,
  SideNavigation,
  SpaceBetween
} from '@cloudscape-design/components';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
// Page components
import { MainPage } from './Screens/Components/landingPage/MainPage';
import { DocumentTableView } from './Screens/DocumentTableView';
import { TopNavigationBar } from './Screens/Components/TopNavigation';
import { DocListHelpPanel } from './Screens/sidePanel/doclistHelpPanel';
import { UploadHelpPanel } from './Screens/sidePanel/uploadSidePanel';
import { DocumentContext } from './context/documentContext';
import { UploadCSV } from './Screens/Components/UploadCSV';
// import { BaseLayout } from './Screens/Components/SynopsisPage1';
// import { DetailPage1 } from './Screens/Components/TopicOverview';
// sidepanels
import { ViewerSidePanel } from './Screens/sidePanel/docviewerSidePanel';
import { useContext, useEffect, useState } from 'react';
import { ProtectedRoute } from './Screens/ProtectedRoute';
import { AdminRoute } from './Screens/AdminRoute';
import { EditDomains } from './Screens/Components/EditDomainControls';
import { ManageUsers } from './Screens/Components/ManageUsers';
import { ChangePassword } from './Screens/Components/ChangePassword';
import { Reports } from './Screens/Components/reports';
import { UserContext } from './context/authContext';
import { ErrorReports } from './Screens/Components/errorReportPage';
import { FreeformAnalysis } from './Screens/Components/freeformAnalysis';
import { ClipboardSidePanel } from './Screens/sidePanel/copyToClipboardPanel';
import './App.css';
import { KnowledgeBaseSplash } from './Screens/Components/KnowledegeBaseSplash';
import { DocumentViewerQuestionaire } from './Screens/DocumentViewerQuestionaire';
import { QuestionaireSidePanel } from './Screens/sidePanel/questionaireSideView';
import { DocumentLayoutViewer } from './Screens/DocumentLayoutViewer';
// import 'pdfjs-dist/build/pdf.worker.min.mjs';
import { pdfjs } from 'react-pdf';
import { ReportsView } from './Screens/Components/reportsView';
import { ProjectTableView } from './Screens/projectTableView';
import { DocumentViewerContainer } from './Screens/DocumentViewerContainer';
import { ErrorBoundary } from 'react-error-boundary';
import { UploadDocument } from './Screens/Components/uploadViews/UploadDoc';
import { ProjectBreadcrumbs } from './Screens/breadCrumbs/projectBreadcrumbs';
import { DocumentBreadcrumbs } from './Screens/breadCrumbs/documentViewBreadCrumbs';
import { CheckBox } from 'docx';
import { CreateAccount } from './Screens/Components/accountViews/CreateAccount';
import { CostCalculator } from './Screens/Components/accountViews/costCalulator';
import { text } from 'stream/consumers';
import YouTubeVideo from './Screens/Components/tutorial/sample';
import { Videos } from './Screens/Components/tutorial/videos';
import { UnauthorizedScreen } from './Screens/Components/errorScreen/unauthorizedScreen';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export function App() {
  const {
    selectedItems,
    freeformSelectedItemsKB,
    freeformSelectedItemsQA,
    mainAlert,
    setMainAlert
  } = useContext(DocumentContext);
  const { user } = useContext(UserContext);
  const windowWidth = window.innerWidth;
  const [navItems, setNavItems] = useState<any[]>([
    // { type: 'link', text: 'Analyze Documents', href: 'upload' },
    // {
    //   type: 'link',
    //   text: 'Answer Questionnaires & Surveys',
    //   href: 'upload?type=questionnaire'
    // },
    // { type: 'link', text: 'List Documents', href: 'documents' },
    { type: 'link', text: 'Projects', href: 'projects' },
    { type: 'link', text: 'Search', href: 'freeform' },
    { type: 'divider' }
  ]);
  const [toolsOpen, setToolsOpen] = useState<boolean>(false);
  const [navOpen, setNavOpen] = useState<boolean>(true);
  const [mainContainerWidth, setMainContainerWith] = useState<number>(90);
  const [showNoticeModal, setShowNoticeModal] = useState<boolean>(false);
  const [noticeAccepted, setNoticeAccepted] = useState<boolean>(false);
  const navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    if (user.isAdmin) {
      const temp = navItems;
      const adminSection = {
        type: 'section',
        text: 'Admin',
        items: [
          { type: 'link', text: 'Populate Database', href: 'admin/uploadcsv' },
          { type: 'link', text: 'Edit Controls / Domains', href: 'admin/edit' },
          { type: 'link', text: 'Manage Users', href: 'admin/users' },
          { type: 'link', text: 'Error Logs', href: 'admin/errorlogs' },
          { type: 'link', text: 'Create Account', href: 'admin/account' },
          {
            type: 'link',
            text: 'Cost Calculator',
            href: 'admin/costcalculator'
          },
          { type: 'link', text: 'tutorial', href: 'admin/tutorial' }
        ]
      };
      temp.push(adminSection);
      console.log('temp side nav', temp);
      setNavItems([...temp]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isAdmin]);
  // useEffect(() => {
  //   if (
  //     selectedItems.length > 0 &&
  //     !location.pathname.includes('questionnaire')
  //   ) {
  //     setToolsOpen(true);
  //   }
  // }, [selectedItems]);
  useEffect(() => {
    if (
      (freeformSelectedItemsKB.length > 0 ||
        freeformSelectedItemsQA.length > 0) &&
      location.pathname === '/app/freeform'
    ) {
      setToolsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeformSelectedItemsKB, freeformSelectedItemsQA]);
  useEffect(() => {
    if (toolsOpen) {
      setNavOpen(false);
    }
  }, [toolsOpen]);
  useEffect(() => {
    if (location.pathname.includes('questionnaire')) {
      setToolsOpen(false);
    }
  }, [location.pathname, setToolsOpen]);
  useEffect(() => {
    if (location.pathname.includes('base')) {
      setNavOpen(false);
      setMainContainerWith(95);
    } else {
      setMainContainerWith(75);
    }
    if (location.pathname.includes('videos')) {
      setMainContainerWith(95);
    }
  }, [location.pathname]);

  const handleAcceptNotice = () => {
    setShowNoticeModal(false);
  };

  return (
    <>
      <div
        style={{
          // position: 'fixed',
          top: 0,
          width: '100%',
          overflowY: 'scroll'
        }}
      >
        <TopNavigationBar />

        <AppLayout
          // minContentWidth={windowWidth * 0.65}
          disableContentPaddings
          headerSelector=".b"
          toolsWidth={400}
          toolsOpen={toolsOpen}
          onToolsChange={({ detail }) => setToolsOpen(detail.open)}
          navigationOpen={navOpen}
          onNavigationChange={() => {
            const newState = !navOpen;
            setNavOpen(newState);
          }}
          // maxContentWidth={Number.MAX_VALUE}
          navigation={
            <SideNavigation
              header={{ href: 'landing', text: 'Services' }}
              items={navItems}
              onFollow={(event) => {
                if (!event.detail.external) {
                  event.preventDefault();
                  navigate(`${event.detail.href}`);
                }
              }}
            />
          }
          content={
            <div
              style={{
                width: `${mainContainerWidth}%`,
                // paddingLeft: '1%',
                // maxHeight: '90vh',
                // alignItems: 'center',
                // alignContent: 'center',
                // justifyContent: 'center',
                margin: '1% auto',
                overflowY: 'scroll',
                scrollbarWidth: 'none'
              }}
            >
              {mainAlert.showAlert ? (
                <Alert
                  type={mainAlert.alertType}
                  dismissible
                  onDismiss={() =>
                    setMainAlert({
                      showAlert: false,
                      message: '',
                      alertType: 'success'
                    })
                  }
                >
                  {mainAlert.message}
                </Alert>
              ) : null}
              <Routes>
                {/* <Route element={<ProtectedRoute />}> */}
                <Route path="landing" element={<MainPage />} />
                <Route
                  path="projects/:projectId?"
                  element={<ProjectTableView />}
                />
                <Route
                  path="projects/:projectId?/folder/:folderId?"
                  element={<DocumentViewerContainer />}
                />
                <Route path="upload" element={<UploadDocument />} />
                {/* <Route path="documents" element={<DocumentTableView />} /> */}
                <Route path="reports" element={<ReportsView />} />
                <Route path="changepassword" element={<ChangePassword />} />
                <Route path="freeform" element={<FreeformAnalysis />} />
                <Route path="videos" element={<Videos />} />
                <Route
                  path="tutorial"
                  element={<YouTubeVideo videoId={''} />}
                />
                {/* <Route path="base" element={<BaseLayout />} /> */}
                <Route
                  path="questionnaire"
                  element={
                    <DocumentViewerQuestionaire
                      setShowSynopsis={function (): void {
                        throw new Error('Function not implemented.');
                      }}
                    />
                  }
                />
                <Route path="splash" element={<KnowledgeBaseSplash />} />

                <Route
                  path="base"
                  element={
                    <ErrorBoundary
                      fallback={
                        <p>Whoops, please refresh you browser Window.</p>
                      }
                    >
                      <DocumentLayoutViewer />
                    </ErrorBoundary>
                  }
                />
                {<Route path="authError" element={<UnauthorizedScreen />} />}
                {/* <Route path="filtered" element={<DocumentViewerFiltered  />} /> */}
                {/* </Route> */}
                <Route path="/admin/*" element={<AdminRoute />}>
                  <Route path="uploadcsv" element={<UploadCSV />} />
                  <Route path="edit" element={<EditDomains />} />
                  <Route path="users" element={<ManageUsers />} />
                  <Route path="errorlogs" element={<ErrorReports />} />
                  <Route path="account" element={<CreateAccount />} />
                  <Route path="costcalculator" element={<CostCalculator />} />
                </Route>
              </Routes>
            </div>
          }
          tools={
            <>
              <Routes>
                <Route path="upload" element={<UploadHelpPanel />} />
                <Route path="documents" element={<DocListHelpPanel />} />
                <Route path="base" element={<ViewerSidePanel />} />
                <Route path="freeform" element={<ClipboardSidePanel />} />
                <Route path="projects" element={<DocListHelpPanel />} />å
                <Route
                  path="questionnaire"
                  element={<QuestionaireSidePanel />}
                />
              </Routes>
            </>
          }
          breadcrumbs={
            <>
              <Routes>
                {/* <Route path="projects" element={<ProjectBreadcrumbs />} /> */}
                <Route
                  path="projects/:projectId?/folder/:folderId?"
                  element={<ProjectBreadcrumbs />}
                />
                <Route path="/base" element={<DocumentBreadcrumbs />} />
              </Routes>
            </>
          }
        />
      </div>
      <Modal visible={false}>
        <YouTubeVideo videoId="IjDCf3Wv-_U" />
      </Modal>
      <Modal
        visible={showNoticeModal}
        footer={
          <SpaceBetween size={'xxxs'}>
            <Button
              onClick={() => handleAcceptNotice()}
              disabled={!noticeAccepted}
            >
              Accept
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween size={'s'} direction="vertical">
          <>
            <h3>{`Welcome to the ${process.env.REACT_APP_TITLE}`}</h3>
            <p>
              PLEASE READ CAREFULLY: This platform processes and analyzes
              content based on your uploaded data.
            </p>
            <p>
              By proceeding, you acknowledge and agree that: You are solely
              responsible for: The accuracy and completeness of all uploaded
              content Maintaining and updating your knowledge base Verifying the
              appropriateness of all analysis results Compliance Channel
              Partners and authorized resellers are responsible only for:
              Platform availability as defined in your Service Level Agreement
              (SLA) Core system functionality and computational accuracy
              Technical support as specified in your service contract Analysis
              results and recommendations are dependent on your input data. Any
              errors or omissions resulting from incomplete or inaccurate
              uploads are your responsibility. Important Limitations: Clearpath
              is an administrative assistance tool only The platform does not
              substitute for professional judgment or legal counsel All results
              must be reviewed by qualified professionals in your organization
              Final decisions remain the responsibility of appropriate subject
              matter experts This platform is designed to enhance, not replace,
              professional expertise. Always consult with qualified legal,
              compliance, or other relevant professional advisors before making
              important decisions. This notice will appear monthly to ensure
              ongoing awareness of these terms. User Acknowledgment ☐ By
              checking this box, I acknowledge and agree that: I am authorized
              to make this acknowledgment on behalf of my organization I
              understand that my organization is solely responsible for the
              accuracy and completeness of all uploaded content I confirm that
              my organization will have qualified professionals review all
              results before implementation I understand that Clearpath is an
              administrative tool and does not replace professional judgment or
              legal counsel I accept that neither Compliance Channel Partners
              nor its resellers are responsible for errors or omissions
              resulting from incomplete or inaccurate data provided by my
              organization I will ensure all users within my organization
              understand these limitations and requirements.
            </p>
          </>
          <Checkbox
            onChange={({ detail }) => setNoticeAccepted(detail.checked)}
            checked={noticeAccepted}
          >
            I have read and understand the above text.
          </Checkbox>
        </SpaceBetween>
      </Modal>
    </>
  );
}
