import * as React from 'react';
import Cards from '@cloudscape-design/components/cards';
import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import { DocumentContext } from '../../context/documentContext';
import { useContext, useEffect, useState } from 'react';
import Link from '@cloudscape-design/components/link';
import ShowMoreText from 'react-show-more-text';
import { kbSentenceItem } from '../../models/interfaces';
import {
  FormField,
  Header,
  Input,
  Spinner
} from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { BaseKeyDetail } from '@cloudscape-design/components/internal/events';
import { UserContext } from '../../context/authContext';
import axios from 'axios';
import { CircleSpinner } from './testSpinner';
import { getKBDocLinkAndOpen } from '../../helpers/getAndOpenLink';
import './style/showMore.css';
interface Props {
  filterType: OptionDefinition[];
  parentType: string;
  setLocalAnswerString?: (arg: string) => void;
  localAnswerString?: string;
  handleAddToAnswer: (arg: string) => void;
}
export const KnowledgeBaseModal = (props: Props) => {
  const { filterType, parentType } = props;
  const {
    selectedItems,
    kbLoading,
    sentences,
    selectedItemsKbResults,
    kbSelectedItems,
    setKBSelectedItems,
    kbModalFreeText,
    setKBModalFreeText,
    handleSearch,
    getCurrentSentenceKB
  } = useContext(DocumentContext);
  const { auth } = useContext(UserContext);
  const [filteredResults, setFilteredResults] = useState<kbSentenceItem[]>([]);
  const [preLoading, setPreLoading] = useState<boolean>(false);

  useEffect(() => {
    // if (parentType === 'Synopsis') {
    if (selectedItems.length > 0) {
      const alreadySelected = selectedItems[0].kbSentences;
      setKBSelectedItems(alreadySelected);
    }
    if (parentType === 'QandA') {
      setFilteredResults(selectedItems[0].kbSentences);
    }
  }, [selectedItems, setKBSelectedItems, parentType]);

  useEffect(() => {
    if (selectedItemsKbResults.length === 0) {
      setFilteredResults([]);
    }
    // if (props.parentType !== 'docviewer') {
    if (filterType.length === 0) {
      const temp = selectedItems[0]?.kbSentences
        ? selectedItems[0]?.kbSentences
        : [];
      setFilteredResults([...temp, ...selectedItemsKbResults]);
    } else {
      const filterValArray = filterType.map((ele) => ele.value);
      const temp = selectedItemsKbResults;
      const filteredResults = temp.filter((ele) =>
        filterValArray.includes(ele.knowledge_base_type)
      );
      setFilteredResults(filteredResults);
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType, selectedItemsKbResults, sentences]);

  function handleKeyDown(event: CustomEvent<BaseKeyDetail>): void {
    if (event.detail.key === 'Enter') {
      handleSearch();
    }
  }
  useEffect(() => {
    if (selectedItems[0]?.kbSentences.length > 0) {
      setFilteredResults(selectedItems[0].kbSentences);
    } else {
      setFilteredResults([]);
    }
  }, [selectedItems]);

  useEffect(() => {
    setKBModalFreeText('');
    setKBSelectedItems([]);
  }, []);


  return (
    <>
      <SpaceBetween direction="vertical" size="s">
        <>
          <FormField
            stretch
            description="Search the knowledge base to find answers"
            label="Enter a Question"
          >
            <Input
              value={kbModalFreeText}
              type="search"
              onChange={(event) => setKBModalFreeText(event.detail.value)}
              onKeyDown={(event) => handleKeyDown(event)}
            />
          </FormField>
        </>
        {!kbLoading ? (
          <Cards
            onSelectionChange={({ detail }) => {
              console.log('detail from change', detail);
              setKBSelectedItems(detail?.selectedItems ?? []);
            }}
            selectedItems={kbSelectedItems}
            cardDefinition={{
              header: (item) => (
                <p style={{ fontStyle: 'italic', fontWeight: 'normal' }}>
                  {item.text}
                </p>
              ),
              sections: [
                {
                  id: 'link',
                  // header: 'Link',
                  content: (item) => (
                    // <Link href="https://www.example.com" external>
                    //   {item.Document.documentFileName }
                    // </Link>
                    <Button
                      onClick={() =>
                        getKBDocLinkAndOpen(
                          item.Document._id,
                          item.PageNumber,
                          auth
                        )
                      }
                      href=""
                      variant="link"
                      loading={preLoading}
                    >
                      {item.Document.documentFileName}
                    </Button>
                  )
                },
                {
                  id: 'Context',
                  header: 'Context',
                  content: (item) => (
                    <>
                      {props.parentType === 'QandA' && (
                        <Button
                          variant="inline-icon"
                          iconName="copy"
                          onClick={() =>
                            props.handleAddToAnswer(item.Context.text)
                          }
                        ></Button>
                      )}
                      <span>
                        <ShowMoreText lines={3} anchorClass="showMoreClass">
                          {item?.Context?.text ? item.Context.text : ''}
                        </ShowMoreText>
                      </span>
                    </>
                  )
                },
                {
                  id: 'parameters',
                  header: 'Parameters',
                  content: (item) => item.All_Parameters.join(' ')
                },
                {
                  id: 'kbType',
                  header: 'Type',
                  content: (item) => item.knowledge_base_type
                },
                {
                  id: 'score',
                  header: 'Similarity',
                  content: (item) =>
                    !isNaN(item.score * 100)
                      ? `${(item.score * 100).toFixed(2)} %`
                      : ''
                }
              ]
            }}
            cardsPerRow={[{ cards: 1 }]}
            items={filteredResults}
            // items={selectedItemsKbResults}
            loadingText="Loading resources"
            selectionType="multi"
            trackBy="_id"
            visibleSections={['text', 'link', 'Context', 'score']}
            empty={
              <Box
                margin={{ vertical: 'xs' }}
                textAlign="center"
                color="inherit"
              >
                <SpaceBetween size="m">
                  <b>No resources found</b>
                </SpaceBetween>
              </Box>
            }
          />
        ) : (
          <CircleSpinner />
        )}
      </SpaceBetween>
    </>
  );
};
