import { Box, Container, Header, SpaceBetween } from '@cloudscape-design/components';

export const KnowledgeBaseSplash = () => {
  return (
    <Container
      header={
        <Header>
          Koru Knowledge Base: Generating Insights and Building Knowledge from
          Your Organizational Data.
        </Header>
      }
    >
      <SpaceBetween direction='vertical' size='s'>

      <Box variant="h3">
        Accelerate decision-making with the knowledge you need when you need it.
      </Box>
      <Box variant='h5'>Introduction to the Product (Coming Soon):</Box>
      <Box>
        Koru Knowledge Base unlocks your organization’s wealth of data. Our
        platform actively transforms documents into a dynamic, accessible
        knowledge base. You can now swiftly pinpoint critical data, gain quick
        access to essential information, and ensure the accuracy and consistency
        of your content, driving informed decision-making.
      </Box>
      <Box variant='h5'>How It Works:</Box>
      <Box>
        Koru Knowledge Base actively analyzes your organization's documents to
        create a vital knowledge base for compliance and regulatory needs. The
        system pinpoints and cites specific, relevant information from your
        documents, streamlining the creation of accurate and consistent new
        content. 
        <br />
        This functionality not only makes responses to external parties
        traceable to historical data but also protects against the risks of
        inconsistencies and the unintended assumption of obligations.
      </Box>
      </SpaceBetween>
    </Container>
  );
};
