import { documentTableItem } from './interfaces';

export const tableColumnDefinitions = [
  {
    id: 'title',
    header: 'Document Tile',
    cell: (item: documentTableItem) => item.documentFileName,
    sortingField: 'documentFileName'
  },
  {
    id: 'docStatus',
    header: 'Document Status',
    cell: (item: documentTableItem) => item.status,
    sortingField: 'status'
  },
  {
    id: 'docType',
    header: 'Document Type',
    cell: (item: documentTableItem) => item.documentType,
    sortingField: 'documentType'
  },
  {
    id: 'folder',
    header: 'Folder',
    cell: (item: documentTableItem) => item.folder,
    sortingField: 'folder'
  }
];

export const KBItemTypes = [
  { label: 'Emails', value: 'Emails' },
  { label: 'Technical Specs', value: 'Technical Specs' },
  { label: 'Governance Docs', value: 'Governance Docs' },
  { label: 'Procedural Docs', value: 'Procedural Docs' },
  {
    label: 'Informational Docs',
    value: 'Informational Docs'
  },
  { label: 'Other', value: 'Other' }
];
