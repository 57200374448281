export const getReportTitle = (pageType: string) => {
  const [localPageType] = pageType.split('@');
  switch (true) {
    case localPageType === 'ownership':
      return 'Burden Assignment';
    case localPageType === 'construction':
      return 'Construction Obligations';
    case localPageType === 'general-obligation':
      return 'General Obligations';
    case localPageType === 'service':
      return 'Service Level Requirements';
    case localPageType === 'dates':
      return 'Obligation Due Dates';
    case localPageType === 'financial':
      return 'Financial Information';
    case localPageType === 'people':
      return 'People, Roles & Responsibilities';
    case localPageType === 'places':
      return 'Places and Locations';
    default:
      return '';
    // case localPageType === '':
    //   return '';
  }
};
