import * as xlsx from 'xlsx';
import axios from 'axios';

export const createErrorLogCSV = async (auth: any) => {
  const result = await axios.get(
    `${process.env.REACT_APP_API}/allErrors`,
    auth
  );
  console.log('result error logs', result.data);
  
  if (result.data.length > 0) {
    const logs = result.data;
    const sheetData = [];
    const allWorksheets = [];
    let workbook = xlsx.utils.book_new();
    for (const log of logs) {
      const tempObject = {
        'Doc Id': log.documentId._id,
        'Document Name': log.documentId.documentFileName,
        log: log.error
      };
      sheetData.push({
        ...tempObject
      });
      let worksheet = xlsx.utils.json_to_sheet(sheetData!);
      let sheetObject = {
        worksheet: worksheet,
        name: `ErrorLogs ${new Date()}`
      };
      allWorksheets.push(sheetObject);
      for (const sheet of allWorksheets) {
        xlsx.utils.book_append_sheet(
          workbook,
          sheet.worksheet,
          `${sheet.name.substring(0, 25)}`
        );
      }
      const blob = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      workbook = xlsx.utils.book_new();
      const url = window.URL.createObjectURL(
        new Blob([blob], { type: 'application/octet-stream' })
      );
      const a = document.createElement('a');
      a.href = url;
      a.download = `reportMultiDoc.xlsx - ${new Date()}`;
      a.click();
      window.URL.revokeObjectURL(url);
      return;
    }
  } else {
    return;
  }
};
