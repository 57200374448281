import {
  Table,
  Container,
  Button,
  SpaceBetween,
  Header,
  Box,
  ContentLayout,
  ExpandableSection,
  Select,
  TableProps,
  Textarea,
  ColumnLayout,
  Link
} from '@cloudscape-design/components';

// context
// const evidences = [
//   { name: 'evidence 1' },
//   { name: 'evidence 2' },
//   { name: 'evidence 3' }
// ];
export const UploadHelpPanel = () => {

  return (
    <Container
      header={<Header variant="h2">{`Upload Files for Analysis`}</Header>}
    >
      <SpaceBetween direction="vertical" size="xl">
        Select documents and pdfs for Koru to analyze. Each document will be
        analyzed in detail to discover content that will have a compliance
        impact to you such as compliance type: obligations and requirements that
        you need to be aware of. Koru also determines the compliance topics such
        as privacy and then provides you information from your Koru Knowledge
        Base that is relevant to the topics so that you can assess the
        compliance impact.
      </SpaceBetween>
    </Container>
  );
};
