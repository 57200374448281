import {
  Alert,
  AlertProps,
  Button,
  Container,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Textarea
} from '@cloudscape-design/components';
import { BaseChangeDetail } from '@cloudscape-design/components/input/interfaces';
import { NonCancelableEventHandler } from '@cloudscape-design/components/internal/events';
import { ChangeEvent, useContext, useState } from 'react';
import { UserContext } from '../../../context/authContext';
import axios from 'axios';
type Address = {
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
};
enum AddressNames {
  street1 = 'street1',
  street2 = 'street2',
  city = 'city',
  state = 'state',
  zip = 'zip',
  country = 'country'
}

export const CreateAccount = () => {
  //context
  const { auth } = useContext(UserContext);

  // state
  const [address, setAddress] = useState<Address>({
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    country: ''
  });
  const [companyName, setCompanyName] = useState<string>('');
  const [primaryContact, setPrimaryContact] = useState<string>('');
  const [tel, setTel] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [primaryEmail, setPrimaryEmail] = useState<string>('');
  const [accountManager, setAccountManager] = useState<string>('');
  const [alertInfo, setAlertInfo] = useState<{
    showAlert: boolean;
    alertMessage: string;
    alertType: AlertProps.Type;
  }>({ showAlert: false, alertMessage: '', alertType: 'success' });
  const [createAccountLoading, setCreateAccountLoading] =
    useState<boolean>(false);
  const handleChange = (detail: string, name: AddressNames) => {
    console.log('hanlde change', detail, name);
    const temp = address;
    temp[name] = detail;
    console.log('combo', temp[name]);
    // const str = JSON.stringify(temp);
    // const parse = JSON.parse(str);
    setAddress({ ...temp });
  };

  const createAccount = async () => {
    try {
      setCreateAccountLoading(true);
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/account?type=createNewCustomer`,
        {
          account: {
            companyName: companyName,
            address: address,
            contactNumber: tel,
            primaryAdmin: primaryContact,
            primaryEmail: primaryEmail,
            accountManager: accountManager
          }
        },
        auth
      );
      setAlertInfo({
        alertMessage: `Created New Customer with account no - ${result.data.result.customerId}`,
        alertType: 'success',
        showAlert: true
      });
      setCreateAccountLoading(false);
    } catch (e) {
      const error = e as Error;
      setCreateAccountLoading(false);
      setAlertInfo({
        alertMessage: `Oops something went wrong. Please try again`,
        alertType: 'error',
        showAlert: true
      });
    }
  };
  const handleReset = () => {
    setAddress({
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    });
    setAccountManager('');
    setCompanyName('');
    setTel('');
    setPrimaryContact('');
    setPrimaryEmail('');
    setNotes('');
  };

  return (
    <>
      {alertInfo.showAlert && (
        <Alert
          type={alertInfo.alertType}
          onDismiss={() =>
            setAlertInfo({
              showAlert: false,
              alertMessage: '',
              alertType: 'success'
            })
          }
        >
          {alertInfo.alertMessage}
        </Alert>
      )}
      <Container
        header={
          <Header
            actions={
              <SpaceBetween size={'xs'} direction="horizontal">
                <Button onClick={() => handleReset()}>Cancel</Button>
                <Button
                  onClick={() => createAccount()}
                  loading={createAccountLoading}
                >
                  Create Account
                </Button>
              </SpaceBetween>
            }
          >
            Create New Customer Account
          </Header>
        }
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%',
            paddingTop: '5%'
          }}
        >
          <div
            style={{
              height: '100%',
              width: '45%'
            }}
          >
            <SpaceBetween size="l">
              <FormField label="Street">
                <Input
                  name="street1"
                  value={address.street1}
                  onChange={({ detail }) =>
                    handleChange(detail.value, AddressNames.street1)
                  }
                  placeholder="123 Main St"
                />
              </FormField>

              <FormField label="Street">
                <Input
                  name="street1"
                  value={address.street2}
                  onChange={({ detail }) =>
                    handleChange(detail.value, AddressNames.street2)
                  }
                  placeholder="Suite A"
                />
              </FormField>

              <FormField label="City">
                <Input
                  name="city"
                  value={address.city}
                  onChange={({ detail }) =>
                    handleChange(detail.value, AddressNames.city)
                  }
                  placeholder="City"
                />
              </FormField>

              <FormField label="State">
                <Input
                  name="state"
                  value={address.state}
                  onChange={({ detail }) =>
                    handleChange(detail.value, AddressNames.state)
                  }
                  placeholder="State"
                />
              </FormField>

              <FormField label="ZIP Code">
                <Input
                  name="zip"
                  value={address.zip}
                  onChange={({ detail }) =>
                    handleChange(detail.value, AddressNames.zip)
                  }
                  placeholder="ZIP Code"
                />
              </FormField>
              <FormField label="Country">
                <Input
                  name="country"
                  value={address.country}
                  onChange={({ detail }) =>
                    handleChange(detail.value, AddressNames.country)
                  }
                  placeholder="United States"
                />
              </FormField>
            </SpaceBetween>
          </div>
          <div
            style={{
              height: '100%',
              width: '45%'
            }}
          >
            <SpaceBetween size="l">
              <FormField label="Company Name">
                <Input
                  value={companyName}
                  onChange={({ detail }) => setCompanyName(detail.value)}
                  placeholder="ACME Comapny"
                />
              </FormField>
              <FormField label="Primary Contact">
                <Input
                  value={primaryContact}
                  onChange={({ detail }) => setPrimaryContact(detail.value)}
                  placeholder="Joe Blogs"
                />
              </FormField>
              <FormField label="Tel No">
                <Input
                  value={tel}
                  onChange={({ detail }) => setTel(detail.value)}
                  placeholder="(206) 867 5309"
                />
              </FormField>
              <FormField label="Primary Email">
                <Input
                  value={primaryEmail}
                  onChange={({ detail }) => setPrimaryEmail(detail.value)}
                  placeholder="jBlogs@acmecompany.com"
                />
              </FormField>
              <FormField label="Account Manager">
                <Input
                  value={accountManager}
                  onChange={({ detail }) => setAccountManager(detail.value)}
                  placeholder="your name"
                />
              </FormField>
              <FormField label="Notes">
                <Textarea
                  rows={4}
                  value={notes}
                  onChange={({ detail }) => setNotes(detail.value)}
                />
              </FormField>
            </SpaceBetween>
          </div>
        </div>
      </Container>
    </>
  );
};
