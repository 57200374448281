import {
  Container,
  Header,
} from '@cloudscape-design/components';

export const QuestionaireSidePanel = () => {
  return (
    <Container
      header={
        <Header variant="h2">{`Answer Questionnaires & Surveys (AQS)`}</Header>
      }
    >
      <p>
        The AQS feature streamlines the process of answering questionnaires and
        surveys by leveraging AI and your Knowledge Base. It allows for
        efficient response creation, knowledge reuse, and traceability.
      </p>

      <h4>Answering Questions</h4>
      <ol>
        <li>Select a sentence/question to answer</li>
        <li>
          In the modal that appears, you'll see two main components: Q&A Results
          and Build Answer
        </li>
      </ol>

      <h5>Q&A Results</h5>
      <ul>
        <li>View similar questions and answers from your AnswerBank</li>
        <li>Copy useful answers to the Build Answer section</li>
        <li>'Pin' Q&A results to create traceability</li>
      </ul>

      <h5>Build Answer</h5>
      <ul>
        <li>Edit and format your answer in the text editor</li>
        <li>
          Add internal notes in the text field below (not included in the final
          answer)
        </li>
        <li>
          Use the 'Research' button to search your Knowledge Base for more
          information
        </li>
      </ul>

      <h5>Knowledge Map</h5>
      <ul>
        <li>Appears when you click 'Research'</li>
        <li>
          Shows similar sentences and their context from your Knowledge Base
        </li>
        <li>Copy relevant context to your answer</li>
        <li>Filter results by document type using the dropdown menu</li>
      </ul>

      <h4>Saving and Managing Responses</h4>
      <ul>
        <li>
          Click 'Save' to close the modal and return to the Questionnaire page
        </li>
        <li>Your answer and notes will appear in the respective columns</li>
        <li>Edit responses anytime using the 'View Selected' button</li>
        <li>'Download Response' generates a spreadsheet of your answers</li>
        <li>
          'Publish' adds your Q&A pairs to the AnswerBank (note: published
          questionnaires can't be edited)
        </li>
      </ul>

      <h4>Additional Features</h4>
      <ul>
        <li>
          Use 'Search your Questions' to find existing answers in the AnswerBank
        </li>
      </ul>

      <h4>Tips for Effective Use</h4>
      <ul>
        <li>
          Regularly update your Knowledge Base to improve research results
        </li>
        <li>
          Use consistent terminology when answering to enhance future matching
        </li>
        <li>
          Leverage the AnswerBank to maintain consistency across similar
          questionnaires
        </li>
        <li>Use internal notes to provide context or rationale for answers</li>
      </ul>
    </Container>
  );
};
