import * as React from 'react';
import BreadcrumbGroup, {
  BreadcrumbGroupProps
} from '@cloudscape-design/components/breadcrumb-group';
import { ProjectContext } from '../../context/projectContext';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const ProjectBreadcrumbs = () => {
  const { mainProjects } = useContext(ProjectContext);
  const [trailArray, setTrailArray] = useState<BreadcrumbGroupProps.Item[]>([]);
  const location = useLocation();

  const createTrail = () => {
    const currentParts = location.pathname.split('/');
    const project = mainProjects.filter((ele) => ele._id === currentParts[3]);
    const tempTrail = [
      { text: 'Home', href: '/' },
      {
        text: project[0]?.projectName,
        href: `/app/projects/${project[0]?._id}`
      },
      { text: ``, href: `` }
    ];
    setTrailArray(tempTrail);
  };
  useEffect(() => {
    if (mainProjects.length > 0) {
      createTrail();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, mainProjects]);

  return (
    <div
      style={{
        left: 0
      }}
    >
      <BreadcrumbGroup items={trailArray} ariaLabel="Breadcrumbs" />
    </div>
  );
};
