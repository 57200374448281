import { Button, Container, Header, SpaceBetween } from "@cloudscape-design/components"
import { DocumentContext } from "../../context/documentContext";
import { useContext, useState } from "react";
import { DomainLayout } from "./DomainLayout";
import { ControlLayout } from "./ControlLayout";
export const EditDomains = () => {
  const {domains, controls} = useContext(DocumentContext)
  const [domainView, setDomainView] = useState<boolean>(true)
  return (
    <Container
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="m">
              <Button onClick={() => setDomainView(true)}>Domains</Button>
              <Button onClick={() => setDomainView(false)}>Controls</Button>
            </SpaceBetween>
          }
        ></Header>
      }
    >
      <div
        style={{
          overflowY: 'scroll',
          maxHeight: '75vh',
          width: '100%',
        }}
      >
        {domainView ? (
          <>
            {domains
              ? domains.map((ele) => <DomainLayout domain={ele} />)
              : null}
          </>
        ) : (
          <>
            {controls
              ? controls.map((ele) => <ControlLayout control={ele} />)
              : null}
          </>
        )}
      </div>
    </Container>
  );
}