import { sentenceItem } from "../models/interfaces";

export const sortSentences = (sentenceArray: sentenceItem[]) => {
  const sorted = sentenceArray.sort((a,b) => a.sentenceIndex - b.sentenceIndex);
  return sorted;
} 

export const sortAndRemoveHidden = (sentenceArray: sentenceItem[]) => {
    const sorted = sentenceArray.sort((a, b) => a.sentenceIndex - b.sentenceIndex);
    const value = sorted.reduce((acc, curr) => {
          if(!curr.hidden) {
            if(curr.type === 'obligation') {
              curr.filterWord = `${curr.predicted_label_language}-${curr.type}`
            }
            acc.push(curr);
          }
          return acc;
    }, [] as sentenceItem[]);
    return value;
} 