import {
  Table,
  Container,
  Button,
  SpaceBetween,
  Header,
  Box,
  ContentLayout,
  ExpandableSection,
  Select,
  TableProps,
  Textarea,
  ColumnLayout,
  Link
} from '@cloudscape-design/components';
import { DocumentContext } from '../../../context/documentContext';
import { useContext, useEffect, useState } from 'react';
import { INotes } from '../../../models/interfaces';
import { Notes } from './components/notes';
// const MockNotes: INotes[] = [
//   { text: 'first note', owner: 'roger', date: '2024-12-01' },
//   { text: 'second note', owner: 'louis couwenberg', date: '2024-06-11' }
// ];
export const WorkWithDoc = () => {
  const {
    setSelectedDomainOption,
    selectedDomainOption,
    selectedControlOptions,
    setSelectedControlOptions,
    domains,
    controls,
    selectedItems,
    notesValue,
    setNotesValue,
    updateSentence,
    updateSentenceLoading
  } = useContext(DocumentContext);
  const [selectDomains, setSelectDomains] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectControls, setSelectControls] = useState<
    { label: string; value: string }[]
  >([]);
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  useEffect(() => {
    if (
      selectedItems.length > 0 &&
      selectedDomainOption &&
      selectedControlOptions
    ) {
      if (
        notesValue !== '' ||
        // selectedItems[0].predicted_label_domain !==
        //   selectedDomainOption.label ||
        selectedItems[0].predicted_label_control !==
          selectedControlOptions.label
      ) {
        setShowSaveButton(true);
      } else {
        setShowSaveButton(false);
      }
    }
  }, [selectedItems, selectedDomainOption, selectedControlOptions, notesValue]);

  useEffect(() => {
    const tempDomain = [];
    const tempControl = [];
    for (const domain of domains) {
      tempDomain.push({ label: domain.domainName, value: domain.domainCode });
    }
    for (const control of controls) {
      tempControl.push({
        label: control.controlName,
        value: control.controlName
      });
    }

    setSelectDomains(tempDomain);
    setSelectControls(tempControl);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domains, controls]);

  useEffect(() => {
    if (selectedItems.length === 0) return;
    const selectedDomainFromItem = selectDomains.filter(
      (ele) => ele.label === selectedItems[0].predicted_label_domain
    );
    setSelectedDomainOption(selectedDomainFromItem[0]);
    const selectedControlFromItem = selectControls.filter(
      (ele) => ele.label === selectedItems[0].predicted_label_control
    );
    setSelectedControlOptions(selectedControlFromItem[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);
  return (
    <div>
      {selectedItems.length === 1 ? (
        <SpaceBetween direction="vertical" size="s">
          {/* <Select
            selectedOption={selectedDomainOption}
            onChange={({ detail }) =>
              setSelectedDomainOption(detail.selectedOption)
            }
            expandToViewport
            options={selectDomains}
          /> */}
          <Select
            selectedOption={selectedControlOptions}
            onChange={({ detail }) =>
              setSelectedControlOptions(detail.selectedOption)
            }
            expandToViewport
            options={selectControls}
          />
          <p style={{fontStyle: 'italic', fontSize: "1.3em", backgroundColor: "yellow" }}>{selectedItems[0].text}</p>
          {/* {selectedItems.length > 0 && (
            <Textarea
              onChange={({ detail }) => setNotesValue(detail.value)}
              value={notesValue}
              placeholder="You can type note here!"
            />
          )} */}
          {selectedItems.length > 0 && (
            <Notes
              notes={selectedItems[0].notes}
              setNotesValue={setNotesValue}
              notesValue={notesValue}
            />
          )}
          {showSaveButton && (
            <Button
              loading={updateSentenceLoading}
              variant="primary"
              onClick={() => updateSentence()}
            >
              Save
            </Button>
          )}
        </SpaceBetween>
      ) : null}
      {selectedItems.length <= 0 ? (
        <Box>Click on a sentence to select and work with it.</Box>
      ) : null}
    </div>
  );
};
