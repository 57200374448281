import { sentenceItem } from '../models/interfaces';

export const getOwnership = (sentences: sentenceItem[]) => {
  const initailList = sentences.map((ele) => {
    const localArray = ele.burden_attribution.filter((ele) => ele.length > 0);
    return localArray;
  });
  console.log('initail list for burdens', initailList);
  const flatList = initailList.flat(2);
  console.log('flat list', flatList);
  const filteredForCapitals = flatList.filter(
    (ele) => ele.charAt(0) === ele.charAt(0).toUpperCase()
  );

  const finalSet = Array.from(new Set(filteredForCapitals));
  console.log('final set', finalSet);
  return finalSet;
};
