import React from 'react';

interface PasswordCheckerProps {
  value: string;
}

const PasswordChecker: React.FC<PasswordCheckerProps> = ({ value }) => {
  const minLength = value.length >= 8;
  const hasNumber = /\d/.test(value);
  const hasCapitalLetter = /[A-Z]/.test(value);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

  return (
    <div>
      <h3>Password Strength Check</h3>
      <ul>
        <li style={{ color: minLength ? 'green' : 'red' }}>
          {minLength ? '✔' : '✘'} Minimum 8 characters
        </li>
        <li style={{ color: hasNumber ? 'green' : 'red' }}>
          {hasNumber ? '✔' : '✘'} At least one number
        </li>
        <li style={{ color: hasCapitalLetter ? 'green' : 'red' }}>
          {hasCapitalLetter ? '✔' : '✘'} At least one capital letter
        </li>
        <li style={{ color: hasSpecialChar ? 'green' : 'red' }}>
          {hasSpecialChar ? '✔' : '✘'} At least one special character
        </li>
      </ul>
    </div>
  );
};

interface PasswordMatchProps {
  first: string;
  second: string;
}

const PasswordMatch: React.FC<PasswordMatchProps> = ({ first, second }) => {
  const match = first === second && (first !== '' || second !== '');
  return (
    <div>
      <h3>Password Strength Check</h3>
      <ul>
        <li style={{ color: match ? 'green' : 'red' }}>
          {match ? '✔' : '✘'} Passwords Match
        </li>
      </ul>
    </div>
  );
};

export { PasswordChecker, PasswordMatch };
