import {
  Button,
  Container,
  Header,
  SpaceBetween
} from '@cloudscape-design/components';
import { useContext } from 'react';
import { UserContext } from '../../context/authContext';
import { createErrorLogCSV } from '../../helpers/createErrorLogsSheet';

export const ErrorReports = () => {
  const { user, auth } = useContext(UserContext);

  return (
    <Container
      header={
        <Header>
          <SpaceBetween direction="vertical" size="s">
            <h1>Create Reports</h1>
            <SpaceBetween size={'s'} direction="horizontal">
              {user.isAdmin ? (
                <Button
                  variant="primary"
                  onClick={() => createErrorLogCSV(auth)}
                >
                  Error Report
                </Button>
              ) : null}
            </SpaceBetween>
          </SpaceBetween>
        </Header>
      }
    ></Container>
  );
};
