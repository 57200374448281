import {
  Box,
  Button,
  Cards,
  Container,
  ExpandableSection,
  Header,
  Multiselect,
  Select,
  SpaceBetween
} from '@cloudscape-design/components';
import { ProjectContext } from '../../../context/projectContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { DocumentContext } from '../../../context/documentContext';
import axios from 'axios';
import { UserContext } from '../../../context/authContext';
import { kbSentenceItem, sentenceItem } from '../../../models/interfaces';
import { getKBDocLinkAndOpen } from '../../../helpers/getAndOpenLink';
import ShowMoreText from 'react-show-more-text';
import '../style/showMore.css';
import { useSearchParams } from 'react-router-dom';

export const DocumentMapper = () => {
  const { mainProjects, selectedProjects, currentProjectFolders } =
    useContext(ProjectContext);
  const { auth } = useContext(UserContext);
  const {
    allDocuments,
    selectedItems,
    mappedSaveLoading,
    saveMappedItems,
    primaryDocument,
    updateNetNew,
    sentences
  } = useContext(DocumentContext);
  const [folderToTarget, setFolderToTarget] = useState<OptionDefinition>({
    label: 'Select Folder',
    value: ''
  });
  const [folderOptions, setFolderOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [documentsToTarget, setDocumentsToTarget] = useState<
    OptionDefinition[]
  >([]);
  const [folderDocsList, setFolderDocsList] = useState<
    { label: string; value: string }[]
  >([]);
  const [documentsToSearch, setDocumentsTosearch] = useState<string[]>([]);
  const [mappingLoading, setMappingLoading] = useState<boolean>(false);
  const [mappedResults, setMappedResults] = useState<kbSentenceItem[]>([]);
  const [selectedResults, setSelectedResults] = useState<kbSentenceItem[]>([]);
  const [message, setMessage] = useState<string>(
    'Select Sentence to start Mapping'
  );
  const [expanded, setExpanded] = useState<boolean>(false);
  const [queryParameters] = useSearchParams();
  useEffect(() => {
    if (!(selectedProjects.length > 0)) {
      return;
    } else {
      const optionS = currentProjectFolders.map((ele) => {
        return { label: ele.folderName, value: ele._id };
      });
      setFolderOptions(optionS);
    }
  }, [selectedProjects]);

  useEffect(() => {
    if (documentsToTarget.length > 0 || folderDocsList.length > 0) {
      setDocumentsToTarget([]);
      setFolderDocsList([]);
    }
    const tempDocs = allDocuments.filter(
      (ele) => ele.folder === folderToTarget.value
    );
    const itemsToSet = tempDocs.map((ele) => {
      return { label: ele.documentFileName, value: ele._id };
    });
    setFolderDocsList(itemsToSet);
  }, [allDocuments, folderToTarget]);

  useEffect(() => {
    const tempDocIds = [];
    if (!(documentsToTarget.length > 0) && folderToTarget) {
      const filteredDocs = allDocuments.filter(
        (ele) => ele.folder === folderToTarget.value
      );
      setDocumentsTosearch(filteredDocs.map((ele) => ele._id));
    }
    if (documentsToTarget.length > 0 && folderToTarget) {
      const filteredDocs = documentsToTarget.map((ele) => ele.value!);
      setDocumentsTosearch(filteredDocs);
    }
  }, [folderToTarget, documentsToTarget]);

  const mapToDocuments = async () => {
    try {
      setMappedResults([]);
      setMappingLoading(true);
      const results = await axios.post(
        `${process.env.REACT_APP_KORU_API}/sentenceVector?type=specificScope`,
        { sentence: selectedItems[0], documentIdsToSearch: documentsToSearch },
        auth
      );
      // remove the current selected values and add to front of array before settings state.
      if (selectedItems[0]?.mappedTargetSentences.length > 0) {
        const currentIds = selectedItems[0].mappedTargetSentences.map(
          (ele) => ele._id
        );
        const excludingCurrent = results.data.results.filter(
          (ele: kbSentenceItem) => !currentIds.includes(ele._id)
        );
        const tempArray = [
          ...selectedItems[0].mappedTargetSentences,
          ...excludingCurrent
        ];
        setMappedResults(tempArray);
      } else {
        setMappedResults(results.data.results);
        if (results.data.results.length === 0) {
          setMessage('No similar sentences found');
        }
      }

      setMappingLoading(false);
    } catch (e) {
      const error = e as Error;
      setMappingLoading(false);
      console.log('Error', error.message);
    }
  };

  const saveMapped = async () => {
    console.log('saving mapped items', queryParameters.get('type'));
    const type = queryParameters.get('type');
    if (!type) return;
    console.log('selected results before save =======', selectedResults);
    saveMappedItems(selectedResults, type);
    setSelectedResults([]);
  };

  useEffect(() => {
    setMappedResults([]);
    setSelectedResults([]);
    if (
      selectedItems &&
      selectedItems.length > 0 &&
      selectedItems[0]?.mappedTargetSentences
    ) {
      if (selectedItems[0]?.mappedTargetSentences.length > 0) {
        setMappedResults([...selectedItems[0].mappedTargetSentences]);
        setSelectedResults([...selectedItems[0].mappedTargetSentences]);
        setExpanded(true);
      } else {
        setMessage('Select Sentence to Start Mapping');
      }
    }
  }, [selectedItems, sentences]);

  const isSaveButtonDisabled = () => {
    if (
      selectedItems[0]?.mappedTargetSentences.length > 0 &&
      selectedItems[0]?.mappedTargetSentences.length > selectedResults.length &&
      selectedResults.length > 0
    ) {
      return false;
    }
    if (
      selectedItems[0]?.mappedTargetSentences.length === 0 &&
      selectedResults.length > 0
    ) {
      return false;
    }
    if (
      JSON.stringify(selectedItems[0]?.mappedTargetSentences) !==
      JSON.stringify(selectedResults)
    ) {
      return false;
    }

    return true;
  };

  const showAddInfoButton = useMemo(() => {
    if (selectedItems.length > 0 && primaryDocument) {
      if (selectedItems[0].documentId._id !== primaryDocument.documentId) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }, [selectedItems, primaryDocument]);
  // selectedItems.length > 0 || !primaryDocument ? false : true
  return (
    <Container
      // expanded={expanded}
      // onChange={() => setExpanded(!expanded)}
      // variant="container"
      // headerText="Link Sentences To Documents"
      // headerDescription="Connect information from other documents in this project."
      header={
        <Header
          actions={
            <SpaceBetween size="s" direction="horizontal">
              <SpaceBetween size="xs" direction="horizontal">
                <Select
                  selectedOption={folderToTarget}
                  placeholder="Scope"
                  options={folderOptions}
                  onChange={({ detail }) =>
                    setFolderToTarget(detail.selectedOption)
                  }
                />

                <Multiselect
                  placeholder="Select Documents"
                  selectedOptions={documentsToTarget}
                  options={folderDocsList}
                  onChange={({ detail }) => {
                    console.log('dtail', detail);
                    setDocumentsToTarget([...detail.selectedOptions]);
                  }}
                />
              </SpaceBetween>
              {selectedItems.length > 0 &&
                queryParameters.get('type') === 'aggregated' &&
                primaryDocument && (
                  <Button
                    onClick={() => updateNetNew(primaryDocument!)}
                    disabled={showAddInfoButton}
                    variant={
                      selectedItems[0]?.netNew &&
                      selectedItems[0]?.netNew.length > 0
                        ? 'primary'
                        : 'normal'
                    }
                  >
                    Add Info
                  </Button>
                )}
              <Button
                disabled={
                  documentsToSearch.length > 0 && selectedItems.length > 0
                    ? false
                    : true
                }
                onClick={() => mapToDocuments()}
                loading={mappingLoading}
              >
                Search
              </Button>
              <Button
                disabled={isSaveButtonDisabled()}
                onClick={() => saveMapped()}
                loading={mappedSaveLoading}
              >
                Save
              </Button>
            </SpaceBetween>
          }
        ></Header>
      }
    >
      {/* <div
        style={{
          minHeight: '20vh',
          width: '100%',
          overflowY: 'scroll',
          scrollbarWidth: 'none'
        }}
      > */}
      <SpaceBetween size={'s'}>
        {selectedItems.length > 0 && (
          <p
            style={{
              fontStyle: 'italic',
              fontSize: '1.3em',
              backgroundColor: 'yellow'
            }}
          >
            {selectedItems[0].text}
          </p>
        )}
        {/* <SpaceBetween size="xs" direction="horizontal">
          <Select
            selectedOption={folderToTarget}
            placeholder="Scope"
            options={folderOptions}
            onChange={({ detail }) => setFolderToTarget(detail.selectedOption)}
          />

          <Multiselect
            placeholder="Select Documents"
            selectedOptions={documentsToTarget}
            options={folderDocsList}
            onChange={({ detail }) => {
              console.log('dtail', detail);
              setDocumentsToTarget([...detail.selectedOptions]);
            }}
          />
        </SpaceBetween> */}
        {/* <SpaceBetween size={'s'}> */}
        <div style={{ height: '40vh', overflow: 'scroll' }}>
          <Cards
            onSelectionChange={({ detail }) => {
              console.log('detail from change', detail);
              setSelectedResults(detail?.selectedItems ?? []);
            }}
            selectedItems={selectedResults}
            cardDefinition={{
              header: (item) => (
                <p style={{ fontStyle: 'italic', fontWeight: 'normal' }}>
                  {item.text}
                </p>
              ),
              sections: [
                {
                  id: 'link',
                  // header: 'Link',
                  content: (item) => (
                    // <Link href="https://www.example.com" external>
                    //   {item.Document.documentFileName }
                    // </Link>
                    <Button
                      onClick={() =>
                        getKBDocLinkAndOpen(
                          item.Document._id,
                          item.PageNumber,
                          auth
                        )
                      }
                      href=""
                      variant="link"
                      // loading={preLoading}
                    >
                      {item.Document.documentFileName}
                    </Button>
                  )
                },
                {
                  id: 'Context',
                  header: 'Context',
                  content: (item) => (
                    <>
                      <ShowMoreText lines={3} anchorClass="showMoreClass">
                        {item?.Context?.text ? item.Context.text : ''}
                      </ShowMoreText>
                    </>
                  )
                },

                {
                  id: 'score',
                  header: 'Similarity',
                  content: (item) =>
                    !isNaN(item.score * 100)
                      ? `${(item.score * 100).toFixed(2)} %`
                      : ''
                }
              ]
            }}
            cardsPerRow={[{ cards: 1 }]}
            items={mappedResults}
            // items={selectedItemsKbResults}
            loadingText="Loading resources"
            selectionType="multi"
            trackBy="_id"
            visibleSections={['text', 'link', 'Context', 'score']}
            empty={
              <Box
                margin={{ vertical: 'xs' }}
                textAlign="center"
                color="inherit"
              >
                <SpaceBetween size="m">{message}</SpaceBetween>
              </Box>
            }
          />
        </div>
        {/* </SpaceBetween> */}
      </SpaceBetween>
      {/* </div> */}
    </Container>
  );
};
