import {
  Box,
  Button,
  Container,
  Header,
  SpaceBetween,
  TextContent
} from '@cloudscape-design/components';
// components
import { FileSelector } from './FileSelector';
import { FileProcessing } from '../FileProcessing';
import { DocumentContext } from '../../../context/documentContext';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DOCTYPE, Projects } from '../../../models/interfaces';
import { ProjectContext } from '../../../context/projectContext';
export const UploadDocument = () => {
  const { uploadProcessing } = useContext(DocumentContext);
  const { mainProjects, allFolders } = useContext(ProjectContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = useState<string | null>(null);
  const [projectId, setProjectId] = useState<string>('');
  const [folderId, setFolderId] = useState<string>('');
  const [header, setHeader] = useState<string>('');
  const [names, setNames] = useState<{
    folderName: string;
    projectName: string;
  }>({ folderName: '', projectName: '' });

  const setInfo = () => {
    const query = searchParams.get('type');
    const queryProject = searchParams.get('projectId');
    const folderQuery = searchParams.get('folder');
    if (query) {
      setType(query);
    }
    if (query === DOCTYPE.questionnaire) {
      setHeader('Upload Questionnaire file');
      setType(DOCTYPE.questionnaire);
    } else {
      setHeader('Upload Files for Analysis');
      setType(DOCTYPE.analysis);
    }
    if (queryProject) {
      setProjectId(queryProject);
    }
    if (folderQuery) {
      setFolderId(folderQuery);
    }
    const currentProject: Projects[] = mainProjects?.filter(
      (ele) => ele._id === queryProject
    );
    if (currentProject.length > 0) {
      const currentFolder = allFolders.filter((ele) => ele._id === folderQuery);

      setNames({
        folderName: currentFolder[0].folderName,
        projectName: currentProject[0].projectName
      });
    }
  };
  useEffect(() => {
    setInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, mainProjects]);

  useEffect(() => {
    setInfo();
  }, []);

  return (
    <Container header={<Header variant="h2">{header}</Header>}>
      <SpaceBetween size={'l'} direction="vertical">
        <TextContent>
          {type === DOCTYPE.analysis && (
            <p>
              Upload your PDF document here to harness our AI-powered analysis
              tools. Our system will process your files, extracting key
              information, identifying obligations, and preparing the content
              for in-depth exploration and integration with your knowledge base,
              enabling you to gain valuable insights and make informed decisions
              based on your document content.
            </p>
          )}
          {type === DOCTYPE.questionnaire && (
            <p>
              The Build an Answer feature empowers you to create comprehensive,
              accurate responses to questionnaires and surveys by leveraging
              your organization's collective knowledge and past Q&As. It
              streamlines your response process, ensures consistency across
              projects, and helps you make informed decisions while creating
              traceable links between questions, answers, and source documents.
            </p>
          )}
        </TextContent>
        <Box>
          <p>
            Uploading file to : Project: <b>{names.projectName}</b> {'>>>'}{' '}
            Folder: <b>{names.folderName}</b>
          </p>
        </Box>
        <FileSelector type={type} projectId={projectId} folderId={folderId} />
      </SpaceBetween>
    </Container>
  );
};
