import {
  Box,
  Button,
  Cards,
  ExpandableSection,
  Header,
  Multiselect,
  Select,
  SpaceBetween
} from '@cloudscape-design/components';
import { ProjectContext } from '../../../context/projectContext';
import { useContext, useEffect, useState } from 'react';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { DocumentContext } from '../../../context/documentContext';
import axios from 'axios';
import { UserContext } from '../../../context/authContext';
import {
  kbSentenceItem,
  NetNewSentenceItem,
  sentenceItem
} from '../../../models/interfaces';
import { getKBDocLinkAndOpen } from '../../../helpers/getAndOpenLink';
import ShowMoreText from 'react-show-more-text';
import '../style/showMore.css';
import { isCurrentFiltered } from '../../../helpers/getRowColor';

export const NetNewComponent = () => {
  const { auth } = useContext(UserContext);
  const {
    netNewSentencesForDoc,
    controlFilter,
    docViewFilter,
    burdenAttFilter
  } = useContext(DocumentContext);
  const [filteredSentences, setFilteredSentences] = useState<
    NetNewSentenceItem[]
  >([]);

  useEffect(() => {
    if (
      docViewFilter === null &&
      controlFilter === '' &&
      burdenAttFilter.length === 0
    ) {
      setFilteredSentences(netNewSentencesForDoc);
    } else {
      if (netNewSentencesForDoc.length > 0) {
        const tempFiltered = netNewSentencesForDoc.filter((ele) =>
          isCurrentFiltered(ele, docViewFilter, burdenAttFilter, controlFilter)
        );
        setFilteredSentences(tempFiltered);
      }
    }
  }, [netNewSentencesForDoc, controlFilter, docViewFilter, burdenAttFilter]);

  return (
    <ExpandableSection
      variant="container"
      headerText="Additional Information"
      headerDescription="These sentences are additional information from other documents."
      // defaultExpanded
      headerCounter={`${netNewSentencesForDoc.length}`}
    >
      <SpaceBetween size={'xs'} direction="vertical">
        <div style={{ height: '60vh', overflow: 'scroll' }}>
          <Cards
            cardDefinition={{
              header: (item) => (
                <p style={{ fontStyle: 'italic', fontWeight: 'normal' }}>
                  {item.text}
                </p>
              ),
              sections: [
                {
                  id: 'link',
                  // header: 'Link',
                  content: (item) => (
                    // <Link href="https://www.example.com" external>
                    //   {item.Document.documentFileName }
                    // </Link>
                    <Button
                      onClick={() =>
                        getKBDocLinkAndOpen(
                          item.Document._id,
                          item.PageNumber,
                          auth
                        )
                      }
                      href=""
                      variant="link"
                      // loading={preLoading}
                    >
                      {item.Document.documentFileName}
                    </Button>
                  )
                },
                {
                  id: 'Context',
                  header: 'Context',
                  content: (item) => (
                    <>
                      <ShowMoreText lines={3} anchorClass="showMoreClass">
                        {item?.Context?.text ? item.Context.text : ''}
                      </ShowMoreText>
                    </>
                  )
                }

                // {
                //   id: 'score',
                //   header: 'Similarity',
                //   content: (item) =>
                //     !isNaN(item.score * 100)
                //       ? `${(item.score * 100).toFixed(2)} %`
                //       : ''
                // }
              ]
            }}
            cardsPerRow={[{ cards: 1 }]}
            items={filteredSentences}
            // items={selectedItemsKbResults}
            visibleSections={['text', 'link', 'Context']}
            empty={
              <Box
                margin={{ vertical: 'xs' }}
                textAlign="center"
                color="inherit"
              >
                <SpaceBetween size="m">
                  <b>No resources found</b>
                </SpaceBetween>
              </Box>
            }
          />
        </div>
      </SpaceBetween>
    </ExpandableSection>
  );
};
