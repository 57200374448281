import { SpaceBetween } from '@cloudscape-design/components';
import React from 'react';

interface YouTubeVideoProps {
  videoId: string;
}

const YouTubeVideo: React.FC<YouTubeVideoProps> = ({ videoId }) => {
  return (
    <SpaceBetween direction="vertical" size="s">
      <div
        className="video-container"
        style={{
          position: 'relative',
          paddingBottom: '56.25%',
          height: 0,
          overflow: 'hidden'
        }}
      >
        <video width="600" controls>
          <source
            src={
              'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/video1.mp4'
            }
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </SpaceBetween>
  );
};

export default YouTubeVideo;
