import {
  Grid,
  FormField,
  Input,
  Checkbox,
  Button,
  Box,
  SpaceBetween
} from '@cloudscape-design/components';
import { IUser } from '../../models/interfaces';
import { useEffect, useState } from 'react';

export const UserRow = (props: {
  user: IUser;
  // forcePasswordReset: (arg1: string, arg2: string, arg3: boolean) => void;
  saveUser: (arg1: string, arg2: string, arg3: string, arg4: boolean) => void;
  updateUser: (
    sub: string,
    name: string,
    email: string,
    isAdmin: boolean
  ) => void;
  updatePassword: (sub: string, newPassword: string) => void;
  setSubToUpdate: (sub: string) => void;
  setUpdatePasswordModalVisible: (arg: boolean) => void;
}) => {
  const { user } = props;
  const [currentUserEmail, setCurrentUserEmail] = useState<string>('');
  const [currentName, setCurrentName] = useState<string>('');
  const [currentPassword, setCurrentPassword] = useState<string | null>(null);
  const [currentIsAdmin, setCurrentIsAdmin] = useState<boolean>(false);
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
  console.log('in row user', user);
  useEffect(() => {
    setCurrentUserEmail(props.user.email);
    setCurrentName(props.user.name);
    setCurrentIsAdmin(props.user.isAdmin);
  }, [props]);
  useEffect(() => {
    if (currentPassword === null) {
      setIsValidPassword(false);
      return;
    }
    const regexTest =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (regexTest.test(currentPassword)) {
      setIsValidPassword(true);
    } else {
      setIsValidPassword(false);
    }
  }, [currentPassword]);
  const showSaveButtonFn = () => {
    if (
      user.email !== currentUserEmail ||
      user.name !== currentName ||
      user.isAdmin !== currentIsAdmin
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    const show = showSaveButtonFn();
    console.log('show', show);
    setShowSaveButton(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIsAdmin, currentName, currentUserEmail]);
  console.log('userrow uuser', user);
  return (
    <Grid
      gridDefinition={[
        { colspan: { default: 3, m: 3 } },
        { colspan: { default: 2, m: 2 } },
        { colspan: { default: 2, m: 2 } },
        { colspan: { default: 1, m: 1 } },
        { colspan: { default: 2, m: 2 } },
        { colspan: { default: 2, m: 2 } }
      ]}
    >
      <FormField stretch label="Email">
        <Input
          value={currentUserEmail}
          onChange={(event) => setCurrentUserEmail(event.detail.value)}
        />
      </FormField>
      <FormField stretch label="Display Name">
        <Input
          value={currentName}
          onChange={(event) => setCurrentName(event.detail.value)}
        />
      </FormField>
      <FormField stretch label="Password">
        <Input
          value={!currentPassword ? '' : currentPassword}
          type="text"
          onChange={(event) => setCurrentPassword(event.detail.value)}
        />
      </FormField>
      <FormField stretch label="Admin">
        <Checkbox
          onChange={({ detail }) => setCurrentIsAdmin(detail.checked)}
          checked={currentIsAdmin}
        >
          Admin
        </Checkbox>
      </FormField>
      <FormField label=" ">
        <Button
          onClick={() => {
            props.setSubToUpdate(user.sub);
            props.setUpdatePasswordModalVisible(true);
          }}
          // disabled={!isValidPassword}
        >
          Reset Password
        </Button>
      </FormField>
      {!user.sub ? (
        <Button
          onClick={() =>
            props.saveUser(
              currentUserEmail,
              currentName,
              currentPassword ?? '',
              currentIsAdmin
            )
          }
        >
          Save New User
        </Button>
      ) : (
        <Button
          onClick={() =>
            props.updateUser(
              user.username,
              currentName,
              currentUserEmail,
              currentIsAdmin
            )
          }
          disabled={!showSaveButton}
        >
          Save
        </Button>
      )}
    </Grid>
  );
};
