import {
  Alert,
  Button,
  Container,
  FileUpload,
  Header,
  Select,
  SpaceBetween,
  Spinner
} from '@cloudscape-design/components';
import { useContext, useEffect, useState } from 'react';
import { BaseRow, Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import { UserContext } from '../../context/authContext';
import { DocumentContext } from '../../context/documentContext';
import axios from 'axios';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { AlertType } from '../../models/interfaces';

export const UploadCSV = () => {
  const { auth } = useContext(UserContext);
  const { kBulkUpload } = useContext(DocumentContext);
  const [domainsArray, setDomainsArray] = useState<any[]>([]);
  const [controlsArray, setControlsArray] = useState<any[]>([]);
  const [jsonValue, setJSONvalue] = useState<File | null>(null);
  const [currentType, setCurrentType] = useState<OptionDefinition>({
    label: 'No Selection',
    value: 'NS'
  });
  const [value, setValue] = useState<any[]>([]);
  const [uploadingKB, setUploadingKB] = useState<boolean>(false);
  const [uplaodReady, setUplaodReady] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<AlertType>({
    statusIconAriaLabel: '',
    type: 'success'
  } as AlertType);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>('');

  // domains

  useEffect(() => {
    setUplaodReady(false);
  }, [currentType]);

  const fileUploadError = () => {
    setAlertType({
      statusIconAriaLabel: 'Error',
      type: 'error'
    });
    setShowAlert(true);
    setAlertSuccessMessage('File upload error');
  };
  const handleRowDomain = async (row: BaseRow) => {
    const tempArray = domainsArray;
    tempArray.push(row);
    setDomainsArray([...tempArray]);
  };

  const uploadDomains = async () => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API}/newdomains`,
        { domains: domainsArray },
        auth
      );
      console.log('control insert result', result.status);
      setAlertSuccessMessage('File Uploaded');
      setAlertType({
        statusIconAriaLabel: 'Success',
        type: 'success'
      });
      setShowAlert(true);
    } catch (e) {
      fileUploadError();
    }
  };
  // controls
  const handleRowControls = async (row: BaseRow) => {
    const tempArray = controlsArray;
    tempArray.push(row);
    setControlsArray([...tempArray]);
  };
  const uploadControls = async () => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API}/newcontrols`,
        { controls: controlsArray },
        auth
      );

      console.log('control insert result', result.status);
      setAlertSuccessMessage('File Uploaded');
      setAlertType({
        statusIconAriaLabel: 'Success',
        type: 'success'
      });
      setShowAlert(true);
    } catch (e) {
      fileUploadError();
    }
  };

  const handleInputChange = async (files: File[]) => {
    console.log('e target files', files);
    if (files.length > 0) {
      setValue(files);
      setUplaodReady(true);
      setJSONvalue(files[0]);
    }
  };

  const uploadJSON = async () => {
    if (jsonValue) {
      const formData = new FormData();
      formData.append('file', jsonValue);
      setUploadingKB(true);
      try {
        const jsonUpload = await kBulkUpload(formData);
        if (jsonUpload.status === 200) {
          setValue([]);
          setJSONvalue(null);
          setUplaodReady(false);
          setUploadingKB(false);
          setAlertType({
            statusIconAriaLabel: 'Success',
            type: 'success'
          });
          setAlertSuccessMessage('File successfuly uploaded');
          setShowAlert(true);
        }
        console.log('jsonUpload', jsonUpload);
      } catch (e) {
        setUploadingKB(false);
        fileUploadError();
      }
    } else {
      console.log('no json value');
    }
  };
  function handleAlertDismiss(): void {
    setAlertSuccessMessage('');
    setShowAlert(false);
    setValue([]);
    setJSONvalue(null);
    setDomainsArray([]);
    setControlsArray([]);
  }

  return (
    <>
      <Container
        header={
          <Header
            actions={
              <SpaceBetween size={'s'} direction="horizontal">
                <Select
                  selectedOption={currentType}
                  onChange={({ detail }) =>
                    setCurrentType(detail.selectedOption)
                  }
                  expandToViewport
                  options={[
                    { label: 'Controls', value: 'controls' },
                    { label: 'Domains', value: 'domains' },
                    { label: 'Knowledge Base', value: 'knowledge' }
                  ]}
                />
                {currentType.value === 'knowledge' && uplaodReady ? (
                  <Button onClick={() => uploadJSON()}>
                    Upload To Knowledge Base
                  </Button>
                ) : null}
              </SpaceBetween>
            }
          >
            Admin Uploads
          </Header>
        }
      >
        {showAlert ? (
          <Alert
            dismissible
            statusIconAriaLabel={alertType.statusIconAriaLabel}
            type={alertType.type ? alertType.type : 'info'}
            onDismiss={() => handleAlertDismiss()}
          >
            {alertSuccessMessage}
          </Alert>
        ) : null}
        {currentType.value === 'domains' ? (
          <Importer
            dataHandler={async (rows, { startIndex }) => {
              // required, may be called several times
              // receives a list of parsed objects based on defined fields and user column mapping;
              // (if this callback returns a promise, the widget will wait for it before parsing more data)
              for (const row of rows) {
                // console.log('row', row);
                await handleRowDomain(row);
              }
            }}
            defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
            restartable={true} // optional, lets user choose to upload another file when import is complete
            onClose={({ file, preview, fields, columnFields }) => {
              uploadDomains();
            }}
          >
            <ImporterField name="domainName" label="domainName" />
            <ImporterField name="domainCode" label="domainCode" />
            <ImporterField name="domainDescription" label="domainDescription" />
          </Importer>
        ) : null}
        {currentType.value === 'controls' ? (
          <Importer
            dataHandler={async (rows, { startIndex }) => {
              for (const row of rows) {
                await handleRowControls(row);
              }
            }}
            defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
            restartable={true} // optional, lets user choose to upload another file when import is complete
            onClose={({ file, preview, fields, columnFields }) => {
              uploadControls();
            }}
          >
            <ImporterField name="domainName" label="domainName" />
            <ImporterField name="domainCode" label="domainCode" />
            <ImporterField
              name="controlDescription"
              label="controlDescription"
            />
            <ImporterField name="controlDepartment" label="controlDepartment" />
            <ImporterField name="controlName" label="controlName" />
            <ImporterField name="controlOwners" label="controlOwners" />
            <ImporterField name="controlStatus" label="controlStatus" />
          </Importer>
        ) : null}
        {currentType.value === 'knowledge' ? (
          <>
            {!uploadingKB ? (
              <FileUpload
                onChange={({ detail }) => {
                  handleInputChange(detail.value);
                }}
                value={value}
                i18nStrings={{
                  uploadButtonText: (e) => (e ? 'Choose files' : 'Choose file'),
                  dropzoneText: (e) =>
                    e ? 'Drop files to upload' : 'Drop file to upload',
                  removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                  limitShowFewer: 'Show fewer files',
                  limitShowMore: 'Show more files',
                  errorIconAriaLabel: 'Error'
                }}
                showFileLastModified
                showFileSize
                showFileThumbnail
                tokenLimit={3}
                constraintText=".json"
                multiple={false}
                accept=".json"
              />
            ) : (
              <Spinner />
            )}
          </>
        ) : null}
      </Container>
    </>
  );
};
