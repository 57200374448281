export const isEncrypted = async (file: File) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = async function () {
      try {
        if (reader.result) {
          var files = new Blob([reader.result], { type: 'application/pdf' });
          files.text().then((x) => {
            console.log('isEncrypted', x.includes('Encrypt')); // true, if Encrypted
            console.log(
              'isEncrypted',
              x
                .substring(x.lastIndexOf('<<'), x.lastIndexOf('>>'))
                .includes('/Encrypt')
            );
            console.log(file.name);
            // return x.includes('Encrypt');
            if (x.includes('Encrypt')) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
        }

        // If it succeeds, the PDF is not encrypted
      } catch (error: any) {
        reject(false);
      }
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });

  // reader.readAsArrayBuffer(file);
  // reader.onload = function () {
  //   console.log('reader result', reader.result);
  //   if (reader.result) {
  //     var files = new Blob([reader.result], { type: 'application/pdf' });
  //     files.text().then((x) => {
  //       console.log('isEncrypted', x.includes('Encrypt')); // true, if Encrypted
  //       console.log(
  //         'isEncrypted',
  //         x
  //           .substring(x.lastIndexOf('<<'), x.lastIndexOf('>>'))
  //           .includes('/Encrypt')
  //       );
  //       console.log(file.name);
  //       return x.includes('Encrypt');
  //     });
  //   } else {
  //     return false;
  //   }
  // };
};
