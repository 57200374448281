import { useState, useEffect, useContext } from 'react';
import { DocumentContext } from '../../../context/documentContext';
import { Button, Tabs } from '@cloudscape-design/components';
import { DocumentControlButtons } from './documentControlButtons';
import { filterEnum } from '../../../models/interfaces';
export const ButtonWrapper = () => {
  const { docViewFilter, domains, setSelectedItems, setDocViewFilter } =
    useContext(DocumentContext);
  const [tabArray, setTabArray] = useState<any[]>([]);

  useEffect(() => {
    const tempArray = [];
    const sortedDomains = domains
      .sort((a, b) => a.domainCode.localeCompare(b.domainCode))
      .reverse();
    for (const domain of domains) {
      tempArray.push({
        label: `${domain.domainName.split(')')[1].trim()}`,
        id: `${domain.domainCode}`,
        content: <DocumentControlButtons domain={domain.domainCode} />
      });
    }

    setTabArray(tempArray);
    setDocViewFilter(filterEnum.unclassified_burden);
  }, [domains]);

  return (
    <>
      <Tabs tabs={tabArray} onChange={() => setSelectedItems([])} />
    </>
  );
};
