import { useState, useEffect, useContext } from 'react';
import {
  Table,
  Container,
  Button,
  SpaceBetween,
  Header,
  Box,
  Spinner,
  TextFilter,
  TextContent
} from '@cloudscape-design/components';
import { useSearchParams } from 'react-router-dom';
// interfaces
import { sentenceItem } from '../models/interfaces';

//contexts
import { DocumentContext } from '../context/documentContext';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { QandAModal } from './Components/qandaModal';
import { QandA_CSV } from '../helpers/createCSV';
import PopoverWrapper from './Components/popoverWrapper';
// import dummy dateata
export const DocumentViewerQuestionaire = (props: {
  setShowSynopsis: () => void;
}) => {
  // contexts
  const {
    sentences,
    currentDoc,
    currentDocId,
    setCurrentDocId,
    selectType,
    selectedItems,
    setSelectedItems,
    showQandQModal,
    setShowQandAModal,
    handleCloseQnadAModal,
    setKBSelectedItems,
    setQASelectedItems,
    publishQandADoc,
    getCurrentSentenceQA
  } = useContext(DocumentContext);
  // state for component

  const [queryParameters] = useSearchParams();

  const [filteredSentences, setFilteredSentences] = useState<sentenceItem[]>(
    []
  );

  const { items, actions, filterProps } = useCollection(filteredSentences, {
    filtering: {
      empty: <></>,
      noMatch: (
        <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
      )
    },
    sorting: {},
    selection: {}
  });

  useEffect(() => {
    setFilteredSentences(sentences);
  }, [sentences]);

  useEffect(() => {
    if (queryParameters.get('documentId')) {
      setCurrentDocId(queryParameters.get('documentId')!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParameters]);

  const addSelectionItem = (items: sentenceItem[]) => {
    if (items.length === 0) {
      setSelectedItems([]);
      return;
    }

    if (items.length > 0) {
      setSelectedItems(items);
    }
  };

  useEffect(() => {
    if (selectedItems.length > 0) {
      setShowQandAModal(true);
    }
  }, [selectedItems, setShowQandAModal]);

  const handleDismiss = () => {
    setSelectedItems([]);
    setKBSelectedItems([]);
    setQASelectedItems([]);
    setShowQandAModal(false);
  };

  const handleShowModal = async () => {
    if (currentDoc.status !== 'Published') {
      await getCurrentSentenceQA();
      setShowQandAModal(true);
    } else {
      setShowQandAModal(true);
    }
  };
  useEffect(() => {}, []);

  return (
    <div style={{ maxHeight: '90vh', width: '100%' }}>
      <Container
        header={
          <SpaceBetween size="m">
            <Header variant="h1">
              <SpaceBetween size={'s'} direction="vertical">
                {currentDoc
                  ? `Document Name: ${currentDoc.documentFileName}`
                  : null}
                <TextContent>
                  The Build an Answer feature empowers you to create
                  comprehensive, accurate responses to questionnaires and
                  surveys by leveraging your organization's collective knowledge
                  and past Q&As. It streamlines your response process, ensures
                  consistency across projects, and helps you make informed
                  decisions while creating traceable links between questions,
                  answers, and source documents.
                </TextContent>
              </SpaceBetween>
            </Header>

            {/* <Alert>This is a generic alert.</Alert> */}
          </SpaceBetween>
        }
      >
        <SpaceBetween size="l" direction="vertical">
          <Container
            header={
              <Header
                actions={
                  <>
                    <SpaceBetween size="s" direction="horizontal">
                      <Button onClick={() => QandA_CSV(sentences, currentDoc)}>
                        Download Response
                      </Button>
                      <PopoverWrapper
                        popoverContent={'Publish Answers to Answerbank'}
                        sideToShow={'LEFT'}
                        aboveBelow={'TOP'}
                      >
                        <Button
                          onClick={() => publishQandADoc()}
                          disabled={currentDoc.published === true}
                        >
                          Publish
                        </Button>
                      </PopoverWrapper>
                      <Button
                        disabled={selectedItems.length < 1 ? true : false}
                        onClick={() => handleShowModal()}
                      >
                        View Selected
                      </Button>
                    </SpaceBetween>
                  </>
                }
              ></Header>
            }
          >
            <div style={{ height: '60vh', overflow: 'scroll' }}>
              {currentDocId && sentences.length > 0 ? (
                <Table
                  loadingText="Loading resources"
                  sortingDisabled
                  variant="borderless"
                  items={items}
                  wrapLines
                  // resizableColumns
                  stickyHeader
                  selectedItems={selectedItems}
                  selectionType={selectType}
                  onSelectionChange={({ detail }) =>
                    addSelectionItem(detail.selectedItems)
                  }
                  filter={
                    <TextFilter
                      {...filterProps}
                      filteringPlaceholder="Search your Questions"
                    />
                  }
                  columnDefinitions={[
                    {
                      id: 'text',
                      header: 'Document Sentences',
                      cell: (sentenceItem) => <p>{sentenceItem.text}</p>,
                      width: '50%'
                    },
                    {
                      id: 'answer',
                      header: 'Answer',
                      width: '35%',
                      cell: (sentenceItem) => (
                        <p>
                          {sentenceItem.answer &&
                          sentenceItem.answer.length > 300
                            ? `${sentenceItem.answer!.substring(0, 300)}...`
                            : sentenceItem.answer!}
                        </p>
                      )
                    }
                    // {
                    //   id: 'notes',
                    //   header: 'Notes',
                    //   width: '15%',
                    //   cell: (sentenceItem) => (
                    //     <p>
                    //       {/* {sentenceItem.notes && sentenceItem.notes.length > 100
                    //         ? `${sentenceItem.notes!.substring(0, 300)}...`
                    //         : sentenceItem.notes!} */}
                    //       Notes value
                    //     </p>
                    //   )
                    // }
                  ]}
                  empty={
                    <Box
                      margin={{ vertical: 'xs' }}
                      textAlign="center"
                      color="inherit"
                    >
                      <SpaceBetween size="m">
                        <b>No matches</b>
                      </SpaceBetween>
                    </Box>
                  }
                />
              ) : (
                <Spinner size="large" />
              )}
            </div>
          </Container>
        </SpaceBetween>
      </Container>
      {showQandQModal ? (
        <QandAModal
          handleCloseQnadAModal={handleCloseQnadAModal}
          showQandQModal={showQandQModal}
        />
      ) : null}
    </div>
  );
};
