import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';

export const manageShare = (
  original: OptionDefinition[],
  newList: OptionDefinition[]
) => {
  const originalIds = new Set(original.map((item) => item.value));
  const updatedIds = new Set(newList.map((item) => item.value));
  const addedItems = newList.filter((item) => !originalIds.has(item.value));
  const removedItems = original.filter((item) => !updatedIds.has(item.value));
  return {
    addedItems,
    removedItems
  };
};
