import { ExpandableSection, Tabs } from '@cloudscape-design/components';
import { KnowledgeBaseWrapper } from '../knowledgeBaseWrapper';
import { DocumentMapper } from './DocumentMapper';
import { DocumentContext } from '../../../context/documentContext';
import { useContext } from 'react';
import { KnowledgeBaseWrapperNonExp } from '../knowledgeBaseWrapperNonExpand';

export const ConnectDocument = () => {
  const { setSelectedItems } = useContext(DocumentContext);
  return (
    <ExpandableSection
      headerText="Connect Information"
      variant="container"
      headerDescription="Search Knowledge Base or Project Documents"
    >
      <Tabs
        tabs={[
          {
            label: 'Project',
            id: 'tab2',
            content: <DocumentMapper />
          },
          {
            label: 'Knowledge Base',
            id: 'tab1',
            content: <KnowledgeBaseWrapperNonExp />
          }
        ]}
        // onChange={() => setSelectedItems([])}
      />
    </ExpandableSection>
  );
};
