import { useState, useEffect } from 'react';

type SetValue<T> = (value: T) => void;

function useLocalStorage<T>(
  key: string,
  initialValue?: T
): [T | undefined, SetValue<T>] {
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.error('Error retrieving data from localStorage:', error);
      return initialValue;
    }
  });

  const setValue: SetValue<T> = (value: T) => {
    try {
      setStoredValue(value);
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Error setting data to localStorage:', error);
    }
  };

  useEffect(() => {
    try {
      const item = localStorage.getItem(key);
      if (item !== null) {
        setStoredValue(JSON.parse(item) as T);
      }
    } catch (error) {
      console.error('Error retrieving data from localStorage:', error);
    }
  }, [key]);

  return [storedValue, setValue];
}

export default useLocalStorage;
