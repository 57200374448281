import {
  AppLayout,
  Button,
  Container,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween
} from '@cloudscape-design/components';
import { UserContext } from '../context/authContext';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TopNavigationBar } from './Components/TopNavigation';
import { PasswordChecker, PasswordMatch } from './Components/passwordChecker';

export const LoginPage = () => {
  const { getSignIn, token, cognitoChallenge, passwordUserError } =
    useContext(UserContext);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [remember, setRemember] = useState<boolean>(false);
  const [newpassword1, setNewPassword1] = useState<string>('');
  const [newpassword2, setNewPassword2] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (newpassword1 === newpassword2) {
      setPassword(newpassword1);
    }
  }, [newpassword1, newpassword2]);
  const loginButtondisabled = () => {
    if (!cognitoChallenge) {
      return email === '' || password === '';
    }
    if (cognitoChallenge) {
      return (
        newpassword1 !== newpassword2 ||
        newpassword1 === '' ||
        newpassword2 === ''
      );
    }
  };
  const location = useLocation();
  return (
    <>
      {!token ? (
        <div style={{ position: 'fixed', top: 0, width: '100%' }}>
          <TopNavigationBar />

          <AppLayout
            headerSelector=".b"
            toolsHide
            navigationHide
            content={
              <Container>
                <form onSubmit={(e) => e.preventDefault()}>
                  <Form
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button formAction="none" variant="link">
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => getSignIn(email, password, remember)}
                          disabled={loginButtondisabled()}
                        >
                          Login
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    {!cognitoChallenge ? (
                      <Container header={<Header variant="h2">Login</Header>}>
                        <SpaceBetween direction="vertical" size="l">
                          <FormField label="Email">
                            <Input
                              value={email}
                              type="text"
                              onChange={({ detail }) => setEmail(detail.value)}
                            />
                            {passwordUserError ? (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: '1em',
                                  marginLeft: '2%'
                                }}
                              >
                                Username or Password Error!
                              </p>
                            ) : null}
                          </FormField>
                          <FormField label="Password">
                            <Input
                              value={password}
                              type="password"
                              onChange={({ detail }) =>
                                setPassword(detail.value)
                              }
                            />
                          </FormField>
                          {/* <Checkbox
                            onChange={({ detail }) =>
                              setRemember(detail.checked)
                            }
                            checked={remember}
                          >
                            Remember Me
                          </Checkbox> */}
                        </SpaceBetween>
                      </Container>
                    ) : (
                      <Container
                        header={<Header variant="h2">Change Password</Header>}
                      >
                        <SpaceBetween direction="vertical" size="l">
                          <FormField label="Email">
                            <Input
                              value={email}
                              type="text"
                              onChange={({ detail }) => setEmail(detail.value)}
                            />
                          </FormField>
                          <FormField label="NewPassword">
                            <Input
                              value={newpassword1}
                              type="password"
                              onChange={({ detail }) =>
                                setNewPassword1(detail.value)
                              }
                            />
                            {newpassword1.length > 0 ? (
                              <PasswordChecker value={newpassword1} />
                            ) : null}
                          </FormField>
                          <FormField label="NewPassword">
                            <Input
                              value={newpassword2}
                              type="password"
                              onChange={({ detail }) =>
                                setNewPassword2(detail.value)
                              }
                            />
                            {newpassword2.length > 0 ? (
                              <PasswordMatch
                                first={newpassword1}
                                second={newpassword2}
                              />
                            ) : null}
                          </FormField>
                        </SpaceBetween>
                      </Container>
                    )}
                  </Form>
                </form>
              </Container>
            }
          />
        </div>
      ) : (
        // <Navigate to="/app/landing" />
        navigate(
          location.pathname && location.pathname !== '/login'
            ? `${location.state.pathname}${location.state.search}`
            : '/*'
        )
      )}
    </>
  );
};
