export interface videoItems {
  link: string;
  items: string[];
  heading: string;
}

interface videoPage {
  pageHeading: string;
  videos: videoItems[];
}

export interface IVideos {
  [key: string]: videoPage;
}
export const videosObject: IVideos = {
  card1: {
    pageHeading: 'Compare and Contrast Documents',
    videos: [
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/video1.mp4',
        heading:
          'Inside your project select one or more folders and then multiple documents to view',
        items: []
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/video2.mp4',
        heading: 'View the documents in aggregate View\n\n',
        items: []
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/video3.mp4',
        heading:
          'Compare similar content between documents by category from Explore Document',
        items: []
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/video4.mp4',
        heading: 'Contrast content by:\n\n',
        items: [
          'Redlining text that is different',
          'Add a note to indicate a difference in content'
        ]
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/video5.mp4',
        heading:
          'Compare similar text across documents and Link Sentences To Documents',
        items: [
          'Select text to compare',
          'Use Link Sentences to Documents to compare content similar content in other documents within your project',
          'Create a connection between sentences and Save Mapped'
        ]
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/video6+.mp4',
        heading:
          'Identify gaps between a primary document and secondary document(s)',
        items: ['Use the categories in Explore Document to identify gaps']
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/video1.mp4',
        heading:
          'Link Sentences to Documents and Add Info to the primary document',
        items: []
      }
    ]
  }
};
