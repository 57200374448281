import {
  Container,
  SpaceBetween,
  Button,
  FormField,
  Input,
  Select
} from '@cloudscape-design/components';
import { Header } from 'docx';
import {
  documentTableItem,
  FolderItem,
  Projects
} from '../../models/interfaces';
import { useContext, useEffect, useState } from 'react';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import axios from 'axios';
import { UserContext } from '../../context/authContext';

export const MoveDocumentToProject = (props: {
  filteredFolders: FolderItem[];
  folderList: OptionDefinition;
  setFolderList: (arg: OptionDefinition) => void;
}) => {
  const { filteredFolders, folderList, setFolderList } = props;
  const [allOptions, setAllOptions] = useState<
    { label: string; value: string }[]
  >([]);
  useEffect(() => {
    const tempValue = filteredFolders?.map((ele) => {
      return { label: ele.folderName, value: ele._id };
    });
    setAllOptions(tempValue);
  }, [filteredFolders]);

  return (
    <SpaceBetween size={'xs'}>
      <FormField label="Select Folder to move to">
        <Select
          selectedOption={folderList}
          onChange={({ detail }) => setFolderList(detail.selectedOption)}
          options={allOptions}
        />
      </FormField>
    </SpaceBetween>
  );
};
