import { sentenceItem } from '../models/interfaces';

export const buildNavObject = (sentences: sentenceItem[]) => {
  const accumulator: {
    controls: { [key: string]: number[] };
    buttons: { [key: string]: number[] };
  } = {
    controls: {},
    buttons: {}
  };
  const resultObject = sentences.reduce((acc, curr: sentenceItem) => {
    if (!acc.controls[curr.predicted_label_control]) {
      acc.controls[curr.predicted_label_control] = [curr.PageNumber!];
    } else {
      acc.controls[curr.predicted_label_control].push(curr.PageNumber!);
    }
    const filterButton = isFilterButton(curr);
    if (filterButton !== 'none') {
      if (!acc.buttons[filterButton]) {
        acc.buttons[filterButton] = [curr.PageNumber!];
      } else {
        acc.buttons[filterButton].push(curr.PageNumber!);
      }
    }
    return acc;
  }, accumulator);
  const controlKeys = Object.keys(resultObject.controls);
  for (const controlKey of controlKeys) {
    const tempArray = resultObject.controls[controlKey];
    const tempSet = new Set(tempArray);
    const tempUniqueArray = Array.from(tempSet);
    tempUniqueArray.sort((a, b) => a - b);
    resultObject.controls[controlKey] = tempUniqueArray;
  }
  return resultObject;
};

export const isFilterButton = (sentence: sentenceItem) => {
  switch (true) {
    case sentence.termination ||
      sentence.penalties ||
      sentence.predicted_label_control === 'Termination':
      return 'Termination';
    case sentence.Percent_Information &&
      sentence.Time_Information &&
      sentence.Time_Entities.length > 0:
      return 'Service';
    case sentence.location && sentence.locations.length > 0:
      return 'Location';
    case sentence.predicted_label_control === 'Project Timeline' ||
      sentence.date_arguments.length > 0:
      return 'Project Timelines';
    case sentence.predicted_label_control === 'Financial Details' ||
      sentence.Financial_Information:
      return 'Financial Information';
    case sentence.netNew && sentence.netNew.length > 0:
      return 'New Information';
    default:
      return 'none';
  }
};
