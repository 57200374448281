import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import { FolderItem, IProjectContext, Projects } from '../models/interfaces';
import axios from 'axios';
import { UserContext } from './authContext';
import { useLocation } from 'react-router-dom';
import { DocumentContext } from './documentContext';
interface Props {
  children?: ReactNode;
}
export const ProjectContext = createContext({} as IProjectContext);

export const ProjectContextProvider: React.FC<Props> = (props) => {
  const { auth, token } = useContext(UserContext);
  // const { getAllDocs } = useContext(DocumentContext);
  const [mainProjects, setMainProjects] = useState<Projects[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<Projects[]>([]); // current selectedProject
  const [folderName, setFolderName] = useState<string>('');
  const [folderDescription, setFolderDescription] = useState<string>('');
  const [createFolderLoading, setCreateFolderLoading] =
    useState<boolean>(false);
  const [newFolderModalVisible, setNewFolderModalVisible] =
    useState<boolean>(false);
  const [selectedFolders, setSelectedFolder] = useState<FolderItem[]>([]); // currentSelected Folder
  const [projectIdContext, setProjectIdContext] = useState<string>('');
  const [allFolders, setAllFolders] = useState<FolderItem[]>([]);
  const [currentProjectFolders, setCurrentProjectFolders] = useState<
    FolderItem[]
  >([]);
  const [deleteLoadingFolder, setDeleteLoadingFolder] =
    useState<boolean>(false);
  const location = useLocation();

  const getAllProjects = async (text: string) => {
    if (!token) return;
    try {
      const results = await axios.post(
        `${process.env.REACT_APP_KORU_API}/folder?type=getProjects`,
        {},
        auth
      );
      setMainProjects(results.data.result);
    } catch (e) {
      const error = e as Error;
      console.log('Error', error.message);
    }
  };
  const setSelection = () => {
    if (!projectIdContext) {
      setSelectedProjects([]);
    } else {
      const projectToSet = mainProjects.filter(
        (ele) => ele._id === projectIdContext
      );
      setSelectedProjects(projectToSet);
    }
  };
  const setProjects = () => {
    if (allFolders.length > 0 && selectedProjects.length > 0) {
      const currentFolders = allFolders.filter(
        (ele) => ele.project === selectedProjects[0]._id
      );
      setCurrentProjectFolders(currentFolders);
    } else {
      console.log('no folders');
    }
  };
  useEffect(() => {
    setProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainProjects, allFolders, selectedProjects]);
  // useEffect(() => {
  //   if (!token) return;
  //   getAllProjects('startup');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // useEffect(() => {
  //   getAllProjects('token change');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [token]);
  useEffect(() => {
    setSelection();
  }, [projectIdContext]);

  useEffect(() => {
    setSelection();
  }, [mainProjects]);

  const createNewFolder = async () => {
    setCreateFolderLoading(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/folder?type=newFolder`,
        {
          newFolder: {
            folderName: folderName,
            folderDescription: folderDescription,
            project: selectedProjects[0]._id
          }
        },
        auth
      );
      // getAllProjects('create new folder');
      // getAllDocs();
      setCreateFolderLoading(false);
      setNewFolderModalVisible(false);
      setFolderName('');
    } catch (e) {
      const error = e as Error;
      console.log('error', error.message);
    }
  };

  const updateFolder = async (id: string) => {
    setCreateFolderLoading(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/folder?type=update`,
        {
          updates: {
            folderName: folderName,
            folderDescription: folderDescription
          },
          _id: id
        },
        auth
      );
      // getAllProjects('update folder');
      // getAllDocs();
      setCreateFolderLoading(false);
      setNewFolderModalVisible(false);
      setFolderName('');
    } catch (e) {
      const error = e as Error;
      console.log('error', error.message);
    }
  };

  const deleteFolder = async (id: string) => {
    try {
      setDeleteLoadingFolder(true);
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/folder?type=deleteFolder`,
        {
          _id: id
        },
        auth
      );
      // getAllProjects('delete folder');
      // getAllDocs();
      setDeleteLoadingFolder(false);
    } catch (e) {
      const error = e as Error;
      console.log('error', error.message);
      setDeleteLoadingFolder(false);
    }
  };

  const value = {
    mainProjects,
    setMainProjects,
    getAllProjects,
    selectedProjects,
    setSelectedProjects,
    folderName,
    setFolderName,
    createNewFolder,
    createFolderLoading,
    updateFolder,
    newFolderModalVisible,
    setNewFolderModalVisible,
    deleteFolder,
    selectedFolders,
    setSelectedFolder,
    projectIdContext,
    setProjectIdContext,
    folderDescription,
    setFolderDescription,
    allFolders,
    setAllFolders,
    currentProjectFolders,
    setCurrentProjectFolders,
    deleteLoadingFolder,
    setDeleteLoadingFolder
  };

  return (
    <ProjectContext.Provider value={value}>
      {props.children}
    </ProjectContext.Provider>
  );
};
