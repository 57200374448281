import {
  AlignmentType,
  CarriageReturn,
  Document,
  Footer,
  Header,
  HeadingLevel,
  LevelFormat,
  Packer,
  Paragraph,
  Tab,
  TextRun,
  convertInchesToTwip,
  ExternalHyperlink
} from 'docx';
import { sentenceItem } from '../models/interfaces';
import { getRowColor } from './getRowColor';
const META_TEXT_SIZE = 8;
const MAIN_TEXT_SIZE = 10;
export const createFile = async (
  documentName: string,
  sentences: sentenceItem[],
  reporTitle: string
) => {
  // Documents contain sections, you can have multiple sections per document, go here to learn more about sections
  // This simple example will only contain one section

  const documentChildren = [];

  const documentHeading = new Paragraph({
    text: documentName,
    heading: HeadingLevel.HEADING_1,
    thematicBreak: true
  });
  const sentenceDisplay = (sentence: sentenceItem) => {
    const line = new Paragraph({
      numbering: {
        reference: 'levelOne',
        level: 0,
        instance: 1
      },
      children: [
        new TextRun({
          size: `${MAIN_TEXT_SIZE}pt`,
          text: sentence.text,
          color: getRowColor(sentence)
        })
      ]
    });
    documentChildren.push(line);
  };

  const sentenceWithMetaData = (sentence: sentenceItem) => {
    const parametersChild = [];
    const notesChild = [];
    const kbChild = [];
    // const sentenceText = [];
    if (sentence.All_Parameters.length > 0) {
      const allP = sentence.All_Parameters.join(', ');
      const params = new TextRun({
        size: `${META_TEXT_SIZE}pt`,
        children: [
          new CarriageReturn(),
          // new Tab(),
          'Parameters: ',
          new Tab(),
          `${allP}`
        ]
      });
      parametersChild.push(params);
    }
    if (sentence.notes.length > 0) {
      const params = new TextRun({
        size: `${META_TEXT_SIZE}pt`,
        children: [
          new CarriageReturn(),
          // new Tab(),
          'Notes: ',
          new Tab(),
          `${sentence.notes}`
        ]
      });
      notesChild.push(params);
    }

    // this will add all the kb sentences seletted
    if (sentence.kbSentences.length > 0) {
      const kbHeading = new TextRun({
        color: '#000000',
        font: 'arial',
        size: `${META_TEXT_SIZE}pt`,
        italics: true,
        underline: {
          color: '#000000',
          type: 'single'
        },
        children: [
          new CarriageReturn(),
          // new Tab(),
          'KnowledgeBase: '
        ]
      });
      kbChild.push(kbHeading);
      for (let i = 0; i < sentence.kbSentences.length; i++) {
        const link = new ExternalHyperlink({
          link: 'https://example.com',
          children: [
            new TextRun({
              color: '#000000',
              font: 'arial',
              size: `${META_TEXT_SIZE}pt`,
              italics: true,
              children: [new Tab(), `${sentence.kbSentences[i].docName}`]
            })
          ]
        });
        const tempKB = new TextRun({
          color: '#000000',
          font: 'arial',
          size: `${META_TEXT_SIZE}pt`,
          italics: true,
          children: [
            new CarriageReturn(),
            // // new Tab(),
            `${i + 1}. `,
            // new Tab(),
            `${sentence.kbSentences[i].text}`,
            new CarriageReturn()
            // `Reference: `,
            // new Tab(),
            // `${sentence.kbSentences[i].docName}`
          ]
        });
        // console.log('temp KB', tempKB);
        kbChild.push(tempKB);
        kbChild.push(link);
      }
    }

    const childrenArray = [
      // ...sentenceText,
      ...parametersChild,
      ...notesChild,
      ...kbChild
    ];
    const line = new Paragraph({
      spacing: {
        // after: 150
        before: 0,
        line: 220
      },
      // numbering: {
      //   reference: 'levelOne',
      //   level: 0,
      //   instance: 1
      // },
      indent: {
        left: convertInchesToTwip(0.1),
        hanging: convertInchesToTwip(0.5)
        // firstLine: convertInchesToTwip(0.5)
      },
      // keepLines: true,
      // keepNext: true,
      children: childrenArray
    });
    const sentenceText = new Paragraph({
      spacing: {
        after: 0,
        before: 150
      },
      numbering: {
        reference: 'levelOne',
        level: 0,
        instance: 1
      },
      keepLines: true,
      keepNext: true,
      children: [
        new TextRun({
          size: `${MAIN_TEXT_SIZE}pt`,
          text: sentence.text,
          strike: sentence.redlined ? true : false,
          color: getRowColor(sentence)
        })
      ]
    });
    // sentenceText.push(
    //   new TextRun({
    //     size: `${MAIN_TEXT_SIZE}pt`,
    //     text: sentence.text,
    //     strike: sentence.redlined ? true : false,
    //     color: getRowColor(sentence)
    //   })
    // );
    documentChildren.push(sentenceText);
    documentChildren.push(line);
  };

  documentChildren.push(documentHeading);
  sentences.forEach((ele) => {
    if (
      ele.burden ||
      ele.kbSentences.length > 0 ||
      ele.notes.length > 0 ||
      ele.redlined
    ) {
      sentenceWithMetaData(ele);
    } else {
      sentenceDisplay(ele);
    }
  });
  // uncomment from here down
  const doc = new Document({
    numbering: {
      config: [
        {
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: '%1',
              alignment: AlignmentType.LEFT,
              style: {
                run: {
                  size: `${MAIN_TEXT_SIZE}pt`
                },
                paragraph: {
                  indent: {
                    left: convertInchesToTwip(0.0),
                    hanging: convertInchesToTwip(0.5),
                    firstLine: convertInchesToTwip(0.5)
                  }
                }
              }
            }
          ],
          reference: 'levelOne'
        }
      ]
    },
    sections: [
      {
        headers: {
          default: new Header({
            children: [new Paragraph(`${reporTitle}`)]
          })
        },
        footers: {
          default: new Footer({
            children: [new Paragraph('Confidential')]
          })
        },
        properties: {},
        children: documentChildren
      }
    ]
  });
  // uncomment from here up

  // Used to export the file into a .docx file
  Packer.toBlob(doc).then((blob) => {
    // console.log(blob);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${documentName} - ${reporTitle}.docx`;
    // Simulate a click to trigger the download
    link.click();
  });
};
