import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../context/authContext';
import React, { useContext } from 'react';

export const AdminRoute = (): any => {
  const { token, isAdmin } = useContext(UserContext);
  console.log('hitting admin route ', token);
  if (!isAdmin || !token) {
    //user is not authenticated
    
    return <Navigate to="/app/landing" />;
  }
  return (
    <>
      <Outlet />
    </>
  );
};
