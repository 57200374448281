import {
  Box,
  Button,
  Container,
  FormField,
  Header,
  Slider,
  SpaceBetween
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

export const CostCalculator = () => {
  const [users, setUsers] = useState<number>(0);
  const [storage, setStorage] = useState<number>(0);
  const [pages, setPages] = useState<number>(0);
  const [finalPrice, setFinalPrice] = useState<number>(175);
  const [approxPages, setApproxPages] = useState<number>(0);

  const calculatePrice = () => {
    const tempPrice = 175;
    const userPrice = users * 35;
    const storagePrice = storage * 15;
    const pagesPrice = (pages / 500) * 15;
    const sum = tempPrice + userPrice + storagePrice + pagesPrice;
    setFinalPrice(sum);
  };

  useEffect(() => {
    calculatePrice();
  }, [users, storage, pages]);

  useEffect(() => {
    setApproxPages((storage / 5) * 13000);
  }, [storage]);
  return (
    <Container
      header={
        <Header actions={<Button>Add To Cart</Button>}>Select Services</Header>
      }
      footer={
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}
        >
          <h2>{`Recurring Monthly Charges = $ ${finalPrice}`}</h2>
        </div>
      }
    >
      <SpaceBetween size={'s'} direction="vertical"></SpaceBetween>

      <div>
        <h3>Starting At:</h3>
        <p>
          Our base package includes 5GB of Storage (Document and Metadata) as
          well as 1000 Pages of System analysis per month.
        </p>
      </div>
      <FormField label="Additionl GB of Storeage">
        {approxPages > 0 && (
          <p
            style={{ margin: ' 1% 1%' }}
          >{`approximately ${approxPages} pages`}</p>
        )}
        <Slider
          min={0}
          max={100}
          step={5}
          value={storage}
          onChange={({ detail }) => setStorage(detail.value)}
        />
      </FormField>
      <FormField label="Additional Pages / month">
        <Slider
          min={0}
          max={10000}
          step={500}
          value={pages}
          onChange={({ detail }) => setPages(detail.value)}
        />
      </FormField>
      <FormField label="Additional Users">
        <Slider
          min={0}
          max={10}
          step={1}
          value={users}
          onChange={({ detail }) => setUsers(detail.value)}
        />
      </FormField>
    </Container>
  );
};
