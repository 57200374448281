import {
  Table,
  Container,
  Button,
  SpaceBetween,
  Header,
  Box,
  ContentLayout,
  ExpandableSection,
  Select,
  TableProps,
  Textarea,
  ColumnLayout,
  Link
} from '@cloudscape-design/components';

// context
import { DocumentContext } from '../../context/documentContext';
import { useContext, useEffect, useState } from 'react';

// const evidences = [
//   { name: 'evidence 1' },
//   { name: 'evidence 2' },
//   { name: 'evidence 3' }
// ];
export const ViewerSidePanel = () => {
  const {
    selectedControlOptions,
    selectedDomainOption,
    domains,
    controls,
    setKnowledgeBaseModal,
    setShowModal,
    getCurrentSentenceKB,
    selectedItems,
    setSelectedItemsKbResults,
    setKbLoading
  } = useContext(DocumentContext);
  const [controlDescription, setControlDescription] = useState<string>('');
  const [domainDescription, setDomainDescription] = useState<string>('');
  const [controlStatus, setControlsStatus] = useState<string>('');
  const [owner, setOwner] = useState<string>('');

  useEffect(() => {
    if (!selectedDomainOption?.label) return;
    if (selectedDomainOption.label !== '') {
      const [descDomain] = domains.filter(
        (ele) => ele.domainName.trim() === selectedDomainOption.label?.trim()
      );
      if (descDomain) {
        setDomainDescription(descDomain.domainDescription);
      }
    } else {
      setDomainDescription('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDomainOption]);
  useEffect(() => {
    if (!selectedControlOptions?.label) return;
    if (selectedControlOptions.label !== '') {
      const [descControl] = controls.filter(
        (ele) => ele.controlName.trim() === selectedControlOptions.label?.trim()
      );
      if (descControl) {
        setControlDescription(descControl.controlDescription);
        setControlsStatus(descControl.controlStatus);
        setOwner(descControl.controlOwners);
      }
    } else {
      setControlDescription('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedControlOptions]);
  const handleKnowledgeBaseModal = () => {
    if (selectedItems[0].kbSentences.length > 0) {
      console.log('setting current kb items');
      setSelectedItemsKbResults(selectedItems[0].kbSentences);
      setKbLoading(false);
    } else {
      getCurrentSentenceKB('');
    }
    setShowModal(true);
    setKnowledgeBaseModal(true);
  };

  return (
    <Container header={<Header variant="h2">{`Analyze Documents`}</Header>}>
      <p>
        Streamline your document review process with our powerful AI-driven
        analysis tool.
      </p>
      <>
        <h4>Key Features:</h4>
        <ol>
          <li>
            <b>Smart Obligation Discovery: </b>Automatically identifies and
            categorizes obligations within your documents.
          </li>
          <li>
            <b>
              Explore Document - <i>Interactive Document Explorer</i>:
            </b>{' '}
            Navigate through your document with ease, focusing on specific types
            of obligations.
          </li>
          <li>
            <b>
              Work with Document - <i>Collaborative Workspace</i>:
            </b>{' '}
            Add notes, redline text, and star important sections for team
            review.
          </li>
          <li>
            <b>
              Knowledge Base Results - <i>Trace Obligations</i>:
            </b>
            Cross-reference compliance information and track changes across
            documents within your existing document library for comprehensive
            insights.
          </li>
        </ol>
        <h4>How to Use:</h4>
        <ol>
          <li>Upload your document</li>
          <li>Wait for the analysis to complete</li>
          <li>Click 'View Document' to access the analysis results</li>
          <li>Explore the document using the category buttons</li>
          <li>
            Work with the document by adding notes, redlining, or changing
            categories
          </li>
          <li>Use the Knowledge Base search to find related information</li>
          <li>
            Generate reports or collaborate with your team - from{' '}
            <b>List Documents</b> select the document you want to generate
            reports
          </li>
        </ol>
        <p>
          Analyze Documents empowers you to efficiently review, understand, and
          act on your obligations discovered in businessdocuments.
        </p>
      </>
    </Container>
  );
};
