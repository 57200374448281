import mammoth from 'mammoth';
import sentencesNLP from './nlp';
import { fileUploadItem } from '../models/interfaces';
import { getPdfText } from './pdfExtract';
import axios from 'axios';

function readFile(file: File): Promise<ArrayBuffer> {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target?.result instanceof ArrayBuffer) {
        resolve(event.target.result);
      } else {
        reject(new Error('Failed to read the file as an ArrayBuffer.'));
      }
    };

    reader.onerror = (event) => {
      reject(new Error(`File read error: ${reader.error}`));
    };

    reader.readAsArrayBuffer(file);
  });
}
// const usemammoth = async(fileBuffer: ArrayBuffer) => {
// return await mammoth.extractRawText({ arrayBuffer: fileBuffer });
// }

export const createParagraphs = async (
  files: File[],
  project: string,
  folderId: string
): Promise<fileUploadItem[] | null> => {
  if (!files.length) return null;
  const returnArray: fileUploadItem[] = [];
  // var file = files[0];
  for (let i = 0; i < files.length; i++) {
    const mime = files[i].type;
    let resultFromRead = await readFile(files[i]);
    console.log('result from read', resultFromRead);

    let sentences: string[] = [];
    console.log('file type', mime);
    if (mime === 'application/pdf') {
      console.log('runinng pdf api');
      const formData = new FormData();
      formData.append('file', files[i]);
      // console.log('text pdf', resultFromRead);

      const resultFormAPI = await axios.post(
        `${process.env.REACT_APP_API}/parsePDF`,
        formData
      );
      const sentenceArray = sentencesNLP(resultFormAPI.data);
      const tempObj = {
        sentences: sentenceArray,
        name: files[i].name,
        uploaded: 'not started',
        project: project,
        folder: folderId
      };
      returnArray.push(tempObj);
    }
    if (
      mime ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      console.log('running mammth');
      mammoth
        .extractRawText({ arrayBuffer: resultFromRead })
        .then((resultObject) => {
          return sentencesNLP(resultObject.value);
        })
        .then((arr) => {
          let tempObj = {
            sentences: arr,
            name: files[i].name,
            uploaded: 'not started',
            project: project
          };
          console.log('tempObj', tempObj);
          returnArray.push(tempObj);
          // setSentenceArray([...temp]);
        })
        .catch((err) => console.log(err));
    }
  }
  return returnArray;
};

export const loadJSON = async (
  files: File[]
): Promise<fileUploadItem[] | null> => {
  if (!files.length) return null;
  const returnArray: fileUploadItem[] = [];
  // var file = files[0];
  for (let i = 0; i < files.length; i++) {
    const mime = files[i].type;
    let resultFromRead = await readFile(files[i]);
    console.log('result from read', resultFromRead);

    console.log('file type', mime);

    if (mime === 'application/json') {
      console.log('running json');

      let tempObj = {
        body: resultFromRead,
        name: files[i].name,
        uploaded: 'not started'
      };
      console.log('tempObj', tempObj);
      returnArray.push(tempObj);
      // setSentenceArray([...temp]);
    }
    return returnArray;
  }
  return [{ body: Buffer.from(''), name: '', uploaded: 'not started' }];
};
