import {
  Container,
  Button,
  Header,
  Input,
  FormField,
  SpaceBetween
} from '@cloudscape-design/components';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/authContext';
import { DocumentContext } from '../../context/documentContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { PasswordMatch, PasswordChecker } from './passwordChecker';
import useLocalStorage from '../../hooks/localStorage';

export const ChangePassword = () => {
  const { auth, user } = useContext(UserContext);
  const { setMainAlert } = useContext(DocumentContext);
  const [first, setFirst] = useState<string>('');
  const [second, setSecond] = useState<string>('');
  const [old, setOld] = useState<string>('');
  const [showSave, setShowSave] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [storedAccessToken, setStoredAccessToken] = useLocalStorage<string>(
    'accessToken',
    ''
  );
  const navigate = useNavigate();

  // const updatePassword = async () => {
  //   setIsLoading(true);
  //   const result = await axios.put(
  //     `${process.env.REACT_APP_API}/changepassword`,
  //     { oldpassword: old, newpassword: first },
  //     auth
  //   );
  //   if (result.status === 204) {
  //     setIsLoading(false);
  //     navigate('/app/landing');
  //   }
  // };
  const updatePassword = async () => {
    try {
      console.log('stored Access', storedAccessToken);
      const results = await axios.post(
        `${process.env.REACT_APP_KORU_API}/user?type=changeOwnPassword`,
        {
          oldPassword: old,
          newPassword: second,
          accessToken: storedAccessToken
        },
        // {
        //   headers: {
        //     Authorization: `Bearer ${storedAccessToken}`
        //   }
        // }
        auth
      );
      setMainAlert({
        showAlert: true,
        message: 'Password Reset',
        alertType: 'success'
      });
      console.log(results);
    } catch (e) {
      setMainAlert({
        showAlert: true,
        message: 'Error resetting password',
        alertType: 'error'
      });
    }
  };
  useEffect(() => {
    if (first === second && (first !== '' || second !== '' || old !== '')) {
      setShowSave(true);
    } else {
      setShowSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first, second]);
  return (
    <Container
      header={
        <Header
          actions={
            <Button
              onClick={() => updatePassword()}
              disabled={!showSave}
              loading={isLoading}
            >
              save
            </Button>
          }
        >
          Change Password
        </Header>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        <FormField label="Old password - Required">
          <Input
            type="password"
            value={old}
            onChange={(event) => setOld(event.detail.value)}
          />
        </FormField>
        <FormField label="New Password">
          <Input
            type="password"
            ariaLabel="New Password"
            value={first}
            onChange={(event) => setFirst(event.detail.value)}
          />
          {first.length > 0 ? <PasswordChecker value={first} /> : null}
        </FormField>
        <FormField label="Retype New Password">
          <Input
            type="password"
            value={second}
            onChange={(event) => setSecond(event.detail.value)}
          />
          {second.length > 0 ? (
            <PasswordMatch first={first} second={second} />
          ) : null}
        </FormField>
      </SpaceBetween>
    </Container>
  );
};
