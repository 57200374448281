import {
  Button,
  Checkbox,
  CheckboxProps,
  Container,
  ExpandableSection,
  Header,
  Icon,
  Popover,
  SpaceBetween,
  Toggle
} from '@cloudscape-design/components';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DocumentContext } from '../../../context/documentContext';
import { CircleSpinner } from '../testSpinner';
import axios from 'axios';
import { UserContext } from '../../../context/authContext';
import { documentTableItem, sentenceItem } from '../../../models/interfaces';
import { isCurrentFiltered } from '../../../helpers/getRowColor';
import { ShowSvgArrow } from './iconsSVG';
import { CheckBox } from 'docx';
import PopoverWrapper from '../popoverWrapper';
import { PopoverWrapperText } from '../../../models/textConstants';

interface AggregatedDocumentItem {
  [key: string]: {
    sentences: sentenceItem[];
    document: documentTableItem;
  };
}
export const AggregatedDocumentView = () => {
  const {
    getAggregatedViewSentences,
    aggSentencesLoading,
    sentences,
    setSentences,
    selectedItems,
    setSelectedItems,
    docViewFilter,
    burdenAttFilter,
    controlFilter,
    setControlsNavigation,
    setAggSentencesLoading,
    getAllDocs,
    setFilterButtonNav,
    primaryDocument,
    setPrimaryDocument,
    allDocuments
  } = useContext(DocumentContext);
  const { auth, token } = useContext(UserContext);
  const [queryParameters] = useSearchParams();
  const [linkedUpdateLoading, setLinkedUpdateLoading] =
    useState<boolean>(false);
  const [filteredSentences, setFilteredSentences] = useState<sentenceItem[]>(
    []
  );
  const [localReducedSentences, setLocalReducedSentences] =
    useState<AggregatedDocumentItem>({});
  const [showCheckBoxes, setShowCheckBoxes] = useState<boolean>(false);
  const getSetup = async () => {
    if (!token) return;
    const documentsToGet = queryParameters.get('documentId')?.split(',');
    if (documentsToGet && documentsToGet.length > 0) {
      getAggregatedViewSentences(documentsToGet);
      getBurdensByDoc();
    }
  };
  useEffect(() => {
    getSetup();
  }, []);

  useEffect(() => {
    getSetup();
  }, [token]);

  const getBurdensByDoc = async () => {
    try {
      const documentIds = queryParameters.get('documentId')?.split(',');

      if (documentIds && documentIds.length > 0) {
        const burdensResult = await axios.post(
          `${process.env.REACT_APP_KORU_API}/document?type=documentInfo`,
          {
            documentIds: documentIds
          },
          auth
        );
        setControlsNavigation(burdensResult.data.result.controls);
        setFilterButtonNav(burdensResult.data.result.buttons);
      }
    } catch (e) {
      const error = e as Error;
      console.log('get buttons error', error.message);
    }
  };

  useEffect(() => {
    let accumulator = {} as AggregatedDocumentItem;
    const currentPrimaryDoc = allDocuments.filter(
      (ele) => ele._id === primaryDocument?.documentId
    );
    console.log('current primary doc', currentPrimaryDoc);
    if (currentPrimaryDoc.length > 0 && primaryDocument) {
      accumulator[currentPrimaryDoc[0]._id] = {
        document: currentPrimaryDoc[0],
        sentences: []
      };
    }

    const regex = /^[0-9]+$/;
    if (
      docViewFilter === null &&
      controlFilter === '' &&
      burdenAttFilter.length === 0
    ) {
      const filteredReduced = sentences.reduce((acc, curr) => {
        if (/^[0-9]+$/.test(curr.text)) {
          return acc;
        }
        curr.checked = false;
        if (!acc[curr.documentId_string]) {
          acc[curr.documentId_string] = {
            sentences: [curr],
            document: curr.documentId
          };
        } else {
          acc[curr.documentId_string].sentences.push(curr);
        }
        return acc;
      }, accumulator);
      setLocalReducedSentences(filteredReduced);
    } else {
      const filteredReduced = sentences.reduce((acc, curr) => {
        if (
          isCurrentFiltered(curr, docViewFilter, burdenAttFilter, controlFilter)
        ) {
          curr.checked = false;
          if (!acc[curr.documentId_string]) {
            acc[curr.documentId_string] = {
              sentences: [curr],
              document: curr.documentId
            };
          } else {
            acc[curr.documentId_string].sentences.push(curr);
          }
        }
        return acc;
      }, accumulator);
      setLocalReducedSentences(filteredReduced);
      console.log();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sentences,
    controlFilter,
    docViewFilter,
    burdenAttFilter,
    primaryDocument,
    selectedItems
  ]);
  // const getDocumentFileName = (sentence: sentenceItem) => {
  //   const currentDoc = allDocuments.filter(
  //     (ele) => ele._id === sentence.netNew?
  //   );
  //   if (currentDoc.length > 0) {
  //     return currentDoc[0].documentFileName;
  //   } else {
  //     return '';
  //   }
  // };
  const handleCheckBox = (
    checked: boolean,
    ele: string,
    sentenceIndex: number
  ) => {
    const temp = localReducedSentences;
    temp[ele].sentences[sentenceIndex].checked = checked;
    setLocalReducedSentences({ ...temp });
  };

  useEffect(() => {
    setSelectedItems([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryDocument]);
  const showBoxes = (ele: string) => {
    if (primaryDocument?.documentId !== ele && showCheckBoxes) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (
      selectedItems.length > 0 &&
      primaryDocument &&
      selectedItems[0].documentId._id === primaryDocument?.documentId
    ) {
      setShowCheckBoxes(true);
    } else {
      setShowCheckBoxes(false);
    }
  }, [selectedItems, primaryDocument]);

  const getSelectedChildSentences = () => {
    if (!(Object.keys(localReducedSentences).length > 0)) return [];
    const currentDocs = queryParameters.get('documentId')?.split(',');
    const secondaryDocs = currentDocs?.filter(
      (ele) => ele !== primaryDocument?.documentId
    );
    console.log('secondary docs', secondaryDocs);
    let checkedSentencesIds: string[] = [];
    if (secondaryDocs && secondaryDocs.length > 0) {
      const allSecondarySentences = [];
      for (const key of secondaryDocs) {
        const keySentences = localReducedSentences[key]?.sentences
          ? localReducedSentences[key]?.sentences
          : [];
        allSecondarySentences.push(...keySentences);
      }

      const checkedSentences = allSecondarySentences.filter(
        (ele) => ele.checked
      );
      console.log('checked sentences', checkedSentences);
      checkedSentencesIds = checkedSentences.map((ele) => ele._id);
    }
    return checkedSentencesIds;
  };
  const handleUpdateLinkedSentences = async () => {
    try {
      setLinkedUpdateLoading(true);
      const idArray = getSelectedChildSentences();
      if (idArray.length > 0) {
        const result = await axios.post(
          `${process.env.REACT_APP_KORU_API}/sentence?type=updateLinkedSentences`,
          {
            idArray,
            parentId: selectedItems[0]._id
          },
          auth
        );
        console.log('Result linked update', result.data);
        const tempSentences = sentences;
        const indexOfUpdatedItem = tempSentences.findIndex(
          (ele: sentenceItem) => result.data.result[0]._id === ele._id
        );
        if (indexOfUpdatedItem !== -1) {
          tempSentences[indexOfUpdatedItem] = result.data.result[0];
          setSentences([...tempSentences]);
          setSelectedItems([result.data.result[0]]);
        }
      }
      setLinkedUpdateLoading(false);
    } catch (e) {
      const error = e as Error;
      console.log('Error from linked update', error.message);
      setLinkedUpdateLoading(false);
    }
  };

  return (
    <Container
      header={
        <Header
          actions={
            <PopoverWrapper
              popoverContent={PopoverWrapperText.save_linked_button.text}
              sideToShow={'LEFT'}
              aboveBelow={'BOTTOM'}
            >
              <Button
                onClick={() => handleUpdateLinkedSentences()}
                disabled={getSelectedChildSentences().length > 0 ? false : true}
                loading={linkedUpdateLoading}
              >
                Save Linked Sentences
              </Button>
            </PopoverWrapper>
          }
        >
          Aggregated Document View
        </Header>
      }
      // footer={
      //   <SpaceBetween size={'xxxs'}>
      //     <Button
      //       onClick={() => handleUpdateLinkedSentences()}
      //       disabled={getSelectedChildSentences().length > 0 ? false : true}
      //       loading={linkedUpdateLoading}
      //     >
      //       Save Linked Sentences
      //     </Button>
      //   </SpaceBetween>
      // }
    >
      <SpaceBetween size={'xxxs'} direction="vertical">
        {aggSentencesLoading ? (
          <CircleSpinner />
        ) : (
          Object.keys(localReducedSentences).map((ele) => {
            return (
              <ExpandableSection
                variant="container"
                headerText={
                  localReducedSentences[ele].document.documentFileName
                }
                defaultExpanded
                headerActions={
                  <Toggle
                    checked={
                      primaryDocument?.documentId ===
                      localReducedSentences[ele].document._id
                    }
                    onChange={({ detail }) => {
                      setPrimaryDocument({
                        documentId: localReducedSentences[ele].document._id,
                        fileName:
                          localReducedSentences[ele].document.documentFileName
                      });
                    }}
                  >
                    {primaryDocument?.documentId ===
                    localReducedSentences[ele].document._id
                      ? 'Primary'
                      : 'Secondary'}
                  </Toggle>
                }
              >
                <div
                  style={{
                    maxHeight: '25vh',
                    overflowY: 'scroll',
                    scrollbarWidth: 'thin'
                  }}
                >
                  {/* <h1>{localReducedSentences[ele].document.documentFileName}</h1> */}
                  {localReducedSentences &&
                    localReducedSentences[ele]?.sentences.map(
                      (sentence, sentenceIndex) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                          >
                            
                            <svg width="20px" height="15px">
                              {ShowSvgArrow(sentence)}
                            </svg>
                            {showBoxes(ele) ? (
                              <Checkbox
                                checked={sentence.checked ? true : false}
                                onChange={({ detail }) =>
                                  handleCheckBox(
                                    detail.checked,
                                    ele,
                                    sentenceIndex
                                  )
                                }
                              ></Checkbox>
                            ) : null}

                            <p
                              style={{
                                outline: `${sentence?.sentenceId === selectedItems[0]?.sentenceId ? '2px solid blue' : '1px solid black'}`,
                                borderRadius: '5px',
                                padding: '1% 1%',
                                backgroundColor: '#fafafa',
                                marginLeft: '5%',
                                width: '85%',
                                textDecoration: sentence.redlined
                                  ? 'line-through'
                                  : 'none',
                                textDecorationColor: sentence.redlined
                                  ? 'red'
                                  : 'none'
                              }}
                              onClick={() => {
                                setSelectedItems([sentence]);
                              }}
                            >
                              {sentence.text}
                            </p>
                            
                            {sentence.netNew && sentence.netNew.length > 0 && (
                              <Popover
                                dismissButton={false}
                                size="small"
                                triggerType="custom"
                                wrapTriggerText={false}
                                content={`This is referencing parent ${sentence.netNew && sentence.netNew.length > 0 ? sentence.netNew[0]?.fileName : ''}`}
                              >
                                <Button iconName="status-info" variant="icon" />
                              </Popover>
                            )}
                          </div>
                        );
                      }
                    )}
                </div>
              </ExpandableSection>
            );
          })
        )}
      </SpaceBetween>
    </Container>
  );
};
