import {
  Box,
  Button,
  Container,
  Grid,
  Header,
  SpaceBetween,
  TextContent
} from '@cloudscape-design/components';
import { Navigate, useNavigate } from 'react-router-dom';
import { MainPageCards } from './mainPageCards';

export const MainPage = () => {
  const navigate = useNavigate();
  return (
    <Container
      header={
        <TextContent>
          <h2>Welcome to Koru</h2>
        </TextContent>
      }
    >
      {/* <h2> Decide on the Project Type</h2>
      <p>
        {' '}
        To get started you need to create a project to contain the documents you
        want to analyze. There are two types of projects 'Workspace' and
        'Information Requests' and documents.
      </p>
      <h3> Workspace projects</h3>
      <p>
        {' '}
        Workspace projects are for documents such as agreements, manuals,
        basically anything that is not a questionnaire.
      </p>
      <h3> Information Requests</h3>
      <p>
        {' '}
        Information Request projects are for processing questionnaires and
        surveys you need to answer. In this project type you can publish
        completed questionnaires as FAQs and be able to reuse previous
        responses.
      </p>
      <h2> Get Started</h2>
      <p>
        Create a project by clicking on the <b>Create Project</b> button. Then
        click <b>View Project</b> so you can upload your documents for analysis
        into the project.
      </p>
      Select an existing project and click the <b>View Projec</b>t to upload
      your documents for analysis
      <h2>Working in Projects</h2>
      <p>
        All documents are uploaded into folders within a project. A default set
        of project folders have been created for you. You can rename, delete,
        and create your own folders.
      </p>
      <h3>What you can do</h3>
      <p>
        {' '}
        <b>Map Text Between Documents</b>You can select text in a document and
        map it to any other document in the project regardless of the folder it
        is in. <b>Document Synopsis of Compliance Categories</b>
        You can select a document and click <b>View Document</b> to Explore a
        Document with predefined categories of discovered compliance
        requirements.
        <b>View multiple documents as a single document</b>
        Within a folder all the documents can be view individually or if you
        select multiple documents, you can view them as a single document
        organized according to different topic categories. Group similar types
        of documents into the same folder so that you will be able to view all
        those documents at the same time
      </p>
      <Button onClick={() => navigate('/app/projects')}>
        Click to get started
      </Button> */}
      <MainPageCards />
    </Container>
  );
};
