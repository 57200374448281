import {
  Table,
  Container,
  Button,
  SpaceBetween,
  Header,
  Box,
  ContentLayout,
  ExpandableSection,
  Select,
  TableProps,
  Textarea,
  ColumnLayout,
  Link,
  Cards,
  TextContent
} from '@cloudscape-design/components';
import { useContext } from 'react';
import { DocumentContext } from '../../context/documentContext';
import { QandASentence, kbSentenceItem } from '../../models/interfaces';

export const ClipboardSidePanel = () => {
  const { freeformSelectedItemsKB, freeformSelectedItemsQA } =
    useContext(DocumentContext);

  const copyItemToClipboard = async (item: kbSentenceItem | QandASentence) => {
    console.log('item clicked', item);
    let htmlItem;
    const localObject = item;
    const trueKB = localObject.hasOwnProperty('Context');
    if (trueKB) {
      const itemTransform = item as unknown as kbSentenceItem;
      htmlItem = `<h1>${itemTransform.text}</h1><span>Context: </span><span>${itemTransform.Context}</span><br /><span>Source: </span><span>${itemTransform.docName}</span>`;
    } else {
      const itemTransform = item as unknown as QandASentence;
      htmlItem = `<h1>${itemTransform.text}</h1><span>Context: </span><span>${itemTransform.answer}</span><br /><span>Source: </span>`;
    }

    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([htmlItem], { type: 'text/html' }),
          'text/plain': new Blob([htmlItem], { type: 'text/plain' })
        })
      ]);
      console.log('Formatted text copied to clipboard.');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const copyAllToClipboard = async () => {
    let htmlString = ``;
    for (const cItem of freeformSelectedItemsKB) {
      const htmlItem = `<h1>${cItem.text}</h1><span>Context: </span><span>${cItem.Context}</span><br /><span>Source: </span><span>${cItem.docName}</span>`;
      htmlString = htmlString + '<br /><br />' + htmlItem;
    }
    for (const qItem of freeformSelectedItemsQA) {
      const htmlItem = `<h1>${qItem.text}</h1><span>Answer: </span><span>${qItem.answer}</span><br /><span>Source: </span>`;
      htmlString = htmlString + '<br /><br />' + htmlItem;
    }
    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([htmlString], { type: 'text/html' }),
          'text/plain': new Blob([htmlString], { type: 'text/plain' })
        })
      ]);
      console.log('Formatted text copied to clipboard.');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <Container header={<Header variant="h2">{`Selected Results`}</Header>}>
      <SpaceBetween direction="vertical" size="xl" alignItems="end">
        <SpaceBetween direction="horizontal" size="s" alignItems="center">
          <TextContent>
            <p>Copy Pinned</p>
          </TextContent>
          <Button
            iconName="copy"
            variant="icon"
            onClick={() => copyAllToClipboard()}
          >
            Copy All
          </Button>
        </SpaceBetween>
        <Box>
          <TextContent>Knowedge Base Items</TextContent>
        </Box>
        <Cards
          // onSelectionChange={({ detail }) =>
          //   setLocalSelectedItems(detail?.selectedItems ?? [])
          // }
          // selectedItems={localSelectedItems}

          cardDefinition={{
            header: (item) => (
              <>
                <span style={{ fontStyle: 'italic', fontWeight: 'lighter' }}>
                  {item.text}
                </span>
              </>
            ),
            sections: [
              {
                id: 'Context',
                header: 'Context',
                content: (item) => (
                  <p onClick={() => alert('P clicked')}>{item.Context.text}</p>
                )
              },
              {
                id: 'parameters',
                header: 'Parameters',
                content: (item) => item.All_Parameters.join(' ')
              },
              {
                id: 'kbType',
                header: 'Type',
                content: (item) => item.knowledge_base_type
              },
              {
                id: 'Similarity',
                header: 'Similarity',
                content: (item) =>
                  !isNaN(item.similarity * 100)
                    ? `${(item.similarity * 100).toFixed(2)} %`
                    : ''
              },
              {
                id: 'link',
                header: 'Link',
                content: (item) => <Link href="">Link To Document</Link>
              },
              {
                id: 'copy',
                header: '',
                content: (item) => (
                  <div
                    className="button_div"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      iconName="copy"
                      variant="inline-icon"
                      onClick={() => copyItemToClipboard(item)}
                    />
                  </div>
                )
              }
            ]
          }}
          cardsPerRow={[{ cards: 1 }]}
          items={freeformSelectedItemsKB}
          // items={selectedItemsKbResults}
          loadingText="Loading resources"
          trackBy="_id"
          visibleSections={[
            'text',
            'link',
            'parameters',
            'kbType',
            'Similarity',
            'Context',
            'copy'
          ]}
          empty={
            <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <br />
                <b>Nothing Pinned</b>
              </SpaceBetween>
            </Box>
          }
        />
        <Box>
          <TextContent>Q&A Map Items</TextContent>
        </Box>
        <Cards
          // onSelectionChange={({ detail }) =>
          //   setLocalSelectedItems(detail?.selectedItems ?? [])
          // }
          // selectedItems={localSelectedItems}

          cardDefinition={{
            header: (item) => (
              <>
                <span style={{ fontStyle: 'italic', fontWeight: 'lighter' }}>
                  {item.text}
                </span>
              </>
            ),
            sections: [
              // {
              //   id: 'Context',
              //   header: 'Context',
              //   content: (item) => (
              //     <p onClick={() => alert('P clicked')}>{item.Context}</p>
              //   )
              // },
              // {
              //   id: 'parameters',
              //   header: 'Parameters',
              //   content: (item) => item.All_Parameters.join(' ')
              // },
              // {
              //   id: 'kbType',
              //   header: 'Type',
              //   content: (item) => item.knowledge_base_type
              // },
              // {
              //   id: 'Similarity',
              //   header: 'Similarity',
              //   content: (item) =>
              //     !isNaN(item.similarity * 100)
              //       ? `${(item.similarity * 100).toFixed(2)} %`
              //       : ''
              // },
              // {
              //   id: 'link',
              //   header: 'Link',
              //   content: (item) => <Link href="">{item.docName}</Link>
              // },
              {
                id: 'answer',
                header: 'Answer',
                content: (item) => item.answer
              },
              {
                id: 'copy',
                header: '',
                content: (item) => (
                  <div
                    className="button_div"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      iconName="copy"
                      variant="inline-icon"
                      onClick={() => copyItemToClipboard(item)}
                    />
                  </div>
                )
              }
            ]
          }}
          cardsPerRow={[{ cards: 1 }]}
          items={freeformSelectedItemsQA}
          // items={selectedItemsKbResults}
          loadingText="Loading resources"
          trackBy="_id"
          visibleSections={['text', 'answer', 'copy']}
          empty={
            <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <br />
                <b>Nothing Pinned</b>
              </SpaceBetween>
            </Box>
          }
        />
      </SpaceBetween>
    </Container>
  );
};
