import {
  Button,
  Container,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween
} from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { useState } from 'react';

export const ProjectForm = (props: {
  createNewProject: (
    projectName: string,
    projectDescription: string,
    projectType: string
  ) => void;
  setFolderModal: (arg: boolean) => void;
}) => {
  const [projectName, setProjectName] = useState<string>('');
  const [projectDescription, setProjectDescription] = useState<string>('');
  const [projectType, setProjectType] = useState<OptionDefinition>({
    label: 'Workspace',
    value: 'workspace'
  });

  const handleReset = () => {
    setProjectDescription('');
    setProjectName('');
    setProjectType({
      label: 'Workspace',
      value: 'workspace'
    });
  };

  return (
    <Container
      header={<Header>Create Project</Header>}
      footer={
        <SpaceBetween size={'xs'} direction="horizontal">
          <Button onClick={() => props.setFolderModal(false)}>Cancel</Button>
          <Button
            disabled={!projectName || !projectType.value}
            onClick={() => {
              props.createNewProject(
                projectName,
                projectDescription,
                projectType.value!
              );
              handleReset();
            }}
          >
            Create Project
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween size={'xs'}>
        <FormField label="Project Name">
          <Input
            value={projectName}
            onChange={(event) => setProjectName(event.detail.value)}
          />
        </FormField>
        <FormField label="Project Description - Optional">
          <Input
            value={projectDescription}
            onChange={(event) => setProjectDescription(event.detail.value)}
          />
        </FormField>
        <FormField label="Project Type">
          <Select
            selectedOption={projectType}
            onChange={({ detail }) => setProjectType(detail.selectedOption)}
            options={[
              { label: 'Workspace', value: 'workspace' },
              { label: 'Information Requests', value: 'information_request' }
            ]}
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
};
