import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  FormField,
  Input,
  Checkbox,
  Button,
  Container,
  Header,
  Multiselect,
  SpaceBetween,
  TextContent,
  ExpandableSection,
  Select
} from '@cloudscape-design/components';
import { DocumentContext } from '../../context/documentContext';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { KnowledgeBaseModal } from './KnowledgeBaseModal';
import { UserContext } from '../../context/authContext';
import axios from 'axios';
import {
  KBOptions,
  kbSentenceItem,
  sentenceItem
} from '../../models/interfaces';
import PopoverWrapper from './popoverWrapper';
import { PopoverWrapperText } from '../../models/textConstants';

export const KnowledgeBaseWrapperNonExp = () => {
  const {
    setKBModalFreeText,
    savePinnedLoading,
    selectedItems,
    kbSelectedItems,
    addKBsentences,
    kbFilterOptions,
    getCurrentSentenceKB,
    kbLoading,
    setSelectedItemsKbResults,
    setKbLoading
  } = useContext(DocumentContext);
  const { auth, isDeveloper } = useContext(UserContext);
  const [selectedFilterOption, setSelectedFilterOption] = useState<
    OptionDefinition[]
  >([]);
  const [optionOnePercent, setOptionOnePercent] = useState<number>(60);
  const [optionTwoPercent, setOptionTwoPercent] = useState<number>(60);
  const [optionOneParagraphsPercent, setOptionOneParagraphPercent] =
    useState<number>(60);
  const [kbFilters, setKBFilters] = useState<OptionDefinition[]>([]);

  const addSentencesToReport = async (close: boolean) => {
    addKBsentences(kbSelectedItems, close);
  };

  const reorderResults = (newResults: kbSentenceItem[]) => {
    if (selectedItems[0]?.kbSentences.length > 0) {
      const currentIds = selectedItems[0].kbSentences.map((ele) => ele._id);
      const excludingCurrent = newResults.filter(
        (ele) => !currentIds.includes(ele._id)
      );
      const tempArray = [...selectedItems[0].kbSentences, ...excludingCurrent];
      return tempArray;
    } else {
      return newResults;
    }
  };
  const searchOptionOne = async () => {
    try {
      setKbLoading(true);
      setSelectedItemsKbResults([]);
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/sentenceVector?type=optionOne`,
        {
          type: 'sentence',
          sentence: selectedItems[0],
          sentencePercent: optionOnePercent / 100,
          paragraphPercent: optionOneParagraphsPercent / 100
        },
        auth
      );
      const reorderedResults = reorderResults(result.data.results);
      setSelectedItemsKbResults(reorderedResults);
      setKbLoading(false);
    } catch (e) {
      console.log('error option One', e);
    }
  };
  const searchOptionTwo = async () => {
    try {
      setKbLoading(true);
      setSelectedItemsKbResults([]);
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/sentenceVector?type=optionTwo`,
        {
          sentence: selectedItems[0],
          percent: optionTwoPercent / 100
        },
        auth
      );
      const reorderedResults = reorderResults(result.data.results);
      setSelectedItemsKbResults(reorderedResults);
      setKbLoading(false);
    } catch (e) {
      console.log('error option One', e);
    }
  };

  const isSaveButtonDisabled = () => {
    if (
      selectedItems[0]?.kbSentences.length > 0 &&
      selectedItems[0]?.kbSentences.length !== kbSelectedItems.length
    ) {
      return false;
    }
    if (
      selectedItems[0]?.kbSentences.length === 0 &&
      kbSelectedItems.length > 0
    ) {
      return false;
    }
    if (
      JSON.stringify(selectedItems[0]?.kbSentences) !==
      JSON.stringify(kbSelectedItems)
    ) {
      return false;
    }
    return true;
  };

  return (
    <Container
      // expanded={selectedItems.length > 0}
      // variant="container"
      header={
        <Header
          actions={
            <SpaceBetween size="s" direction="horizontal">
              <PopoverWrapper
                popoverContent={
                  <p style={{ fontSize: '0.8em', padding: '1% 1%' }}>
                    Select specific types or leave blank for all types
                  </p>
                }
                sideToShow="RIGHT"
                aboveBelow="BOTTOM"
              >
                <Multiselect
                  onChange={({ detail }) =>
                    setKBFilters([...detail.selectedOptions])
                  }
                  // hideTokens
                  selectedOptions={kbFilters}
                  options={KBOptions}
                  placeholder={
                    kbFilters.length > 0
                      ? 'Use Selected Filters'
                      : 'Select Filters'
                  }
                ></Multiselect>
              </PopoverWrapper>
              <Button
                onClick={() => {
                  if (kbFilters.length > 0) {
                    getCurrentSentenceKB(
                      'sentence',
                      kbFilters.map((ele) => ele.value!)
                    );
                  } else {
                    getCurrentSentenceKB('sentence');
                  }
                }}
                disabled={selectedItems.length > 0 ? false : true}
                loading={kbLoading}
              >
                Search
              </Button>
              <PopoverWrapper
                popoverContent={PopoverWrapperText.save_pinned_kb.text}
                sideToShow={'LEFT'}
                aboveBelow={'TOP'}
              >
                <Button
                  loading={savePinnedLoading}
                  onClick={() => addSentencesToReport(false)}
                  disabled={isSaveButtonDisabled()}
                >
                  Save
                </Button>
              </PopoverWrapper>
            </SpaceBetween>
          }
        ></Header>
      }
      // headerDescription="Find information from searchable documents."
      // headerActions={
      //   <Header
      //     actions={
      //       <SpaceBetween size={'s'} direction="vertical">
      //         <SpaceBetween size="s" direction="horizontal">
      //           <Button
      //             onClick={() => {
      //               if (kbFilters.length > 0) {
      //                 getCurrentSentenceKB(
      //                   'sentence',
      //                   kbFilters.map((ele) => ele.value!)
      //                 );
      //               } else {
      //                 getCurrentSentenceKB('sentence');
      //               }
      //             }}
      //             disabled={selectedItems.length > 0 ? false : true}
      //             loading={kbLoading}
      //           >
      //             Search
      //           </Button>
      //           <PopoverWrapper
      //             popoverContent={PopoverWrapperText.save_pinned_kb.text}
      //             sideToShow={'LEFT'}
      //             aboveBelow={'TOP'}
      //           >
      //             <Button
      //               loading={savePinnedLoading}
      //               onClick={() => addSentencesToReport(false)}
      //               disabled={isSaveButtonDisabled()}
      //             >
      //               Save
      //             </Button>
      //           </PopoverWrapper>
      //         </SpaceBetween>
      //       </SpaceBetween>
      //     }
      //   ></Header>
      // }
    >
      <SpaceBetween size={'l'} direction="vertical">
        <TextContent>
          <p style={{ fontStyle: 'italic', fontSize: "1.3em", backgroundColor: "yellow" }}>
            {selectedItems[0]?.text ? selectedItems[0].text : ''}
          </p>
        </TextContent>
        {/* <PopoverWrapper
          popoverContent={
            <p style={{ fontSize: '0.8em', padding: '1% 1%' }}>
              Select specific types or leave blank for all types
            </p>
          }
          sideToShow="RIGHT"
          aboveBelow="BOTTOM"
        >
          <Multiselect
            onChange={({ detail }) => setKBFilters([...detail.selectedOptions])}
            // hideTokens
            selectedOptions={kbFilters}
            options={KBOptions}
            placeholder={
              kbFilters.length > 0 ? 'Use Selected Filters' : 'Select Filters'
            }
          ></Multiselect>
        </PopoverWrapper> */}
        <div style={{ height: '60vh', overflow: 'scroll' }}>
          <KnowledgeBaseModal
            filterType={selectedFilterOption}
            parentType="docviewer"
            handleAddToAnswer={() => {}}
          />
        </div>
      </SpaceBetween>
    </Container>
  );
};
