import { sentenceItem } from '../models/interfaces';

export const filteredViewSentences = (
  pageIdentifier: string,
  sentences: sentenceItem[]
) => {
  let returnObject = { sentences: [] as sentenceItem[], header: '' };
  const localId = pageIdentifier.split('@');
  if (localId.length > 1) {
    if (localId[0] === 'obligation') {
      const obligations = sentences.reduce((acc, curr) => {
        if (
          curr.burden &&
          curr.predicted_label_language === 'ComplianceLanguage' &&
          curr.predicted_label_domain === localId[1]
        ) {
          acc.push(curr);
        }
        return acc;
      }, [] as sentenceItem[]);
      returnObject.sentences = obligations;
      returnObject.header = `${localId[1]} - Obligations`;
    }
    if (localId[0] === 'expectations') {
      console.log('xpcitations');
      const expectations = sentences.reduce((acc, curr) => {
        if (
          !curr.burden &&
          curr.predicted_label_domain === localId[1] &&
          curr.predicted_label_language === 'ComplianceLanguage' &&
          curr.confidence_score_language > 0.8
        ) {
          acc.push(curr);
        }
        return acc;
      }, [] as sentenceItem[]);
      returnObject.sentences = expectations;
      returnObject.header = `${localId[1]} - Expectations`;
    }
    if (localId[0] === 'ownership') {
      const ownerships = localId[1].split(':::');
      console.log('ownerships', ownerships);
      const owners = sentences.reduce((acc, curr) => {
        if (curr.burden_attribution.some((ele) => ownerships.includes(ele))) {
          acc.push(curr);
        }
        return acc;
      }, [] as sentenceItem[]);
      returnObject.sentences = owners;
      returnObject.header = `Ownership`;
    }
  } else {
    let temp;
    switch (pageIdentifier) {
      case 'general-obligation':
        temp = sentences.reduce((acc, curr) => {
          if (
            curr.burden &&
            curr.predicted_label_language === 'GeneralLanguage'
          ) {
            acc.push(curr);
          }
          return acc;
        }, [] as sentenceItem[]);
        returnObject.sentences = temp;
        returnObject.header = 'General Obligations';

        break;
      case 'financial':
        temp = sentences.reduce((acc, curr) => {
          if (curr.Financial_Information) {
            curr.viewParameters = curr.Financial_Amounts;
            acc.push(curr);
          }
          return acc;
        }, [] as sentenceItem[]);
        returnObject.sentences = temp;
        returnObject.header = 'Financial Information';
        break;
      case 'privacy':
        temp = sentences.reduce((acc, curr) => {
          if (
            curr.predicted_label_domain === '(PR) PII & Privacy' &&
            curr.burden
          ) {
            acc.push(curr);
          }
          return acc;
        }, [] as sentenceItem[]);
        returnObject.sentences = temp;
        returnObject.header = 'Privacy Obligations';
        break;
      case 'people':
        temp = sentences.reduce((acc, curr) => {
          if (curr.people_org && curr.burden) {
            curr.viewParameters = curr.people_orgs;
            acc.push(curr);
          }
          return acc;
        }, [] as sentenceItem[]);
        returnObject.sentences = temp;
        returnObject.header = 'People, Roles & Responsibilities';
        break;
      case 'places':
        temp = sentences.reduce((acc, curr) => {
          if (curr.location && curr.burden) {
            curr.viewParameters = curr.locations;
            acc.push(curr);
          }
          return acc;
        }, [] as sentenceItem[]);
        returnObject.sentences = temp;
        returnObject.header = 'Places and Locations';
        break;
      case 'dates':
        temp = sentences.reduce((acc, curr) => {
          if (
            curr.Time_Information &&
            curr.Time_Entities &&
            curr.Time_Entities.length > 0 &&
            curr.burden
          ) {
            curr.viewParameters = curr.Time_Entities;
            acc.push(curr);
          }
          return acc;
        }, [] as sentenceItem[]);
        returnObject.sentences = temp;
        returnObject.header = 'Obligations due dates';
        break;
      case 'service':
        temp = sentences.reduce((acc, curr) => {
          if (
            curr.parameters &&
            curr.parameter_arguments &&
            curr.parameter_arguments.length > 0 &&
            curr.burden
          ) {
            curr.viewParameters = curr.parameter_arguments;
            acc.push(curr);
          }
          return acc;
        }, [] as sentenceItem[]);
        returnObject.sentences = temp;
        returnObject.header = 'Service level requirements';
        break;
    }
  }
  return returnObject;
};
